import React from 'react'

const CompleteDishes = (props) => {
    return (
        <>
            <div className="disable-card">
                <div className="dis">
                    <div className="cover-images">
                        <img src="../../assets/images/category-2.jpg" alt="" className="img-fluid" />
                        <h4>{props.DisableHeading1} </h4>
                    </div>
                </div>
                <div className="dis-line">
                    <div className="outer-kitchen">
                        <h3>
                            {props.DisableItem2}
                        </h3>
                        <h4>
                            {props.DisableNumber3}
                        </h4>
                    </div>
                    <span>
                        {props.DisableSize4}
                    </span>
                    <span>
                        {props.DisableCheese5}
                    </span>
                    <button className="visible-button">Complete</button>
                    <button className="not-visible-button">Cancel</button>
                </div>
            </div>
        </>
    )
};

export default CompleteDishes;
