import React from "react";

const StockCard = (props) => {
  return (
    <>
      <div className="card stock-card">
        <img src={props.StockImages} className="card-img-top" alt="..." />
        <div className="card-body stock-body">
          <h5 className="stock-heading">{props.StockHeading}</h5>
          <p className="stock-sub-title">{props.StockTitle}</p>
          <div className="stock-update">
            <p>{props.Update}</p>
            <input type="number" className="form-control" min="1" max="100" />
          </div>
          <div className="availability">
            <span>{props.StockRight}</span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
          <div className="outer-button">
            <button className="visible-button">
              <img
                src="../../assets/images/check.png"
                alt="check"
                className="choose-btn"
              />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockCard;
