import React from "react";

const AvailableTableOrderTime = (props) => {
  return (
    <>
      <div
        className="card-arrow"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        <div className="card-number">
          <div>
            <h4>{props.Table}</h4>
            <h2>{props.Count}</h2>
            <h4>{props.Max}</h4>
          </div>
          <img src={props.CriCle} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time}</p>
          <p>{props.Mins}</p>
          <img src={props.ImagesCriCleGray} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time2}</p>
          <p>{props.Rese}</p>
          <img src={props.ImagesCriCle} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time3}</p>
          <p>{props.Mins3}</p>
          <img src={props.ImagesCriCle3} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time4}</p>
          <p>{props.Mins4}</p>
          <img src={props.ImagesCriCle4} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time5}</p>
          <p>{props.Mins5}</p>
          <img src={props.ImagesCriCle5} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time6}</p>
          <p>{props.Mins6}</p>
          <img src={props.ImagesCriCle6} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time7}</p>
          <p>{props.Mins7}</p>
          <img src={props.ImagesCriCle7} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time8}</p>
          <p>{props.Mins8}</p>
          <img src={props.ImagesCriCle8} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Time9}</p>
          <p>{props.Mins9}</p>
          <img src={props.ImagesCriCle9} alt="" />
        </div>
        <div className="table-time">
          <p>{props.TimePro1}</p>
          <p>{props.MinsPro1}</p>
          <img src={props.ImagesCriClePro1} alt="" />
        </div>
        <div className="table-time">
          <p>{props.TimePro2}</p>
          <p>{props.MinsPro2}</p>
          <img src={props.ImagesCriClePro2} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Customer}</p>
          <p>{props.List}</p>
          <img src={props.ImageCon} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Customer1}</p>
          <p>{props.List1}</p>
          <img src={props.ImageCon1} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Customer2}</p>
          <p>{props.List2}</p>
          <img src={props.ImageCon2} alt="" />
        </div>
        <div className="table-time">
          <p>{props.Customer3}</p>
          <p>{props.List3}</p>
          <img src={props.ImageCon3} alt="" />
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content kitchen-title">
            <div className="modal-header kitchen-modal-header">
              <img
                src="../../assets/images/first.png"
                alt="first.png"
                className="img-fluid"
              />
              <h5 className="modal-title" id="staticBackdropLabel">
                Table 01
              </h5>
              <span>max 4 pax</span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body kitchen-modal-body">
              <div className="row">
                <div className="col-6">
                  <div className="input-group">
                    <span className="input-group-text">08:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">09:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">10:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">11:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">12:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">01:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">02:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">03:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-group">
                    <span className="input-group-text">04:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">05:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">06:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">07:00am</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">08:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">09:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">10:00pm</span>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="not-visible-button-1"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="visible-button-1">
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableTableOrderTime;
