import React from "react";

const Bill = () => {
  return (
    <>
      <table style={{ padding: "10px", width: "100%" }}>
        <thead>
          <tr>
            <th>
              <h2
                style={{
                  fontSize: "35px",
                  color: "#000",
                  fontWeight: "600",
                  marginBottom: "0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Your Order Number 100
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: "500",
                  marginBottom: "10px",
                  textAlign: "center",
                  lineHeight: "30px",
                }}
              >
                Cottage Butcher,
                <br />
                3173 Muskoka District Road 169, Bala
                <br />
                ON P0C 1A0, Canada
                <br />
                <br /> Tax Invoice
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p
                style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}
              >
                Crew id 920020021 - THIRUMANDYAM LOKESH
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}
              >
                INV# 001970100009482
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}
              >
                ORD #00 -REG #1- 04/02/2018 16:14:10
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <table
                className="table"
                style={{ width: "100%", marginTop: "10px" }}
              >
                <thead style={{ fontSize: "18px", color: "#000" }}>
                  <tr>
                    <th>QTY ITEM</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "18px", color: "#000" }}>
                  <tr>
                    <td>1 Swirl</td>
                    <td>$28.58</td>
                  </tr>
                  <tr>
                    <td>Sub Total</td>
                    <td>$28.58</td>
                  </tr>
                  <tr>
                    <td>CGST @ 2.5%</td>
                    <td>0.71</td>
                  </tr>
                  <tr>
                    <td>SGST @ 2.5%</td>
                    <td>0.71</td>
                  </tr>
                  <tr>
                    <td>Eat-In Total</td>
                    <td>30.00</td>
                  </tr>
                  <tr>
                    <td>Total Rounded</td>
                    <td>30.00</td>
                  </tr>
                  <tr>
                    <td>Cash Tendered</td>
                    <td>50.00</td>
                  </tr>
                  <tr>
                    <td>Changes</td>
                    <td>20.00</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#000",
                  marginTop: "20px",
                }}
              >
                We value your feedback. Visit us on:
                <br />
                https://cottagebutcher.com/
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Bill;
