import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_GET_PRODUCTS } from "../Config/Endpoints";
import { getProductDetailsMultiple } from "../Services/Helpers";
import { logoutUser } from "../Services/Storage";

const Menu = (props) => {
  const { gridLayout, addToCart } = props;
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState({ data: [] });

  const handleLayoutChange = (layout) => {
    gridLayout(layout);
    setLayout(layout);
  };

  useEffect(() => {
    let controller = new AbortController();

    const fetchAutocompleteResults = async () => {
      try {
        const response = await fetch(
          `${API_GET_PRODUCTS}?include=catalog,media,price&filter[f_search]=${searchQuery}`,
          {
            signal: controller.signal,
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log("data", data);
        setAutocompleteResults(data);
      } catch (error) {
        console.error("Error fetching autocomplete results:", error);
      }
    };

    if (searchQuery.length >= 2) {
      fetchAutocompleteResults();
    } else {
      setAutocompleteResults({ data: [] });
    }

    // Cancel previous incomplete request when the query changes
    return () => {
      controller.abort();
      controller = new AbortController();
    };
  }, [searchQuery]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleOnSearchClick = (e, item) => {
    addToCart(e, item);
    setSearchQuery("");
    setAutocompleteResults({ data: [] });
  };

  return (
    <>
      <header>
        <ul>
          <li>
            <Link to="/" className="active active-menu">
              Customer
            </Link>
          </li>
          {/* <li>
                        <Link to="/KitchenOrder">Kitchen Order</Link>
                    </li> */}
          {/* <li>
                        <Link to="/Counter">Counter Checkout</Link>
                    </li> */}
          {/* <li>
                        <Link to="/AvailableTable">Table Booking</Link>
                    </li> */}
          {/* <li>
            <Link to="/StockProduct">Menu Stock</Link>
          </li> */}
          <li className="pe-4">
            <Link to="/HistoryOrder">Order History</Link>
          </li>
        </ul>
        <div className="grid-img">
          <div className="search-input">
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              value={searchQuery}
              onChange={handleInputChange}
            />
            <i className="fa fa-search"></i>
          </div>
          <ul className="autocomplete-results">
            {autocompleteResults.data.map((item, key) => (
              <li onClick={(e) => handleOnSearchClick(e, item)} key={key}>
                {item.attributes["product.label"]} @ $
                {getProductDetailsMultiple(autocompleteResults, item).price
                  ? getProductDetailsMultiple(autocompleteResults, item).price
                      .attributes["price.value"]
                  : "-"}
              </li>
            ))}
          </ul>
          {/* <Link
            to="/"
            className={`${layout === "list" ? "active" : null}`}
            onClick={(e) => handleLayoutChange("list", e)}
          >
            <i className="fa fa-list-ul" aria-hidden="true"></i>
          </Link> */}
          {/* <Link
            to="/"
            className={`${layout === "grid" ? "active" : null}`}
            onClick={(e) => handleLayoutChange("grid", e)}
          >
            <i className="fa fa-th-large" aria-hidden="true"></i>
          </Link> */}
          <Link to="/" className="logout" onClick={logoutUser}>
            <i className="fa fa-sign-in" aria-hidden="true"></i>
          </Link>
        </div>
      </header>
    </>
  );
};

export default Menu;
