import React from 'react'

const CardPrice = (props) => {
    return (
        <>

            <div className="customer-dealer">
                <img src={props.ImagesKitchen} alt="" className="img-fluid" />
                <div className="outer-kitchen">
                    <h3>
                        {props.Pork}
                    </h3>
                    <h4>
                        {props.PorkList}
                    </h4>
                </div>
                <span>
                    {props.ListSize}
                </span>
                <span>
                    {props.Extra}
                </span>
                <button className="visible-button">Complete</button>
                <button className="not-visible-button">Cancel</button>
            </div>

        </>
    )
};

export default CardPrice;