import { React, useEffect, useState } from "react";
import { API_GET_CART } from "../Config/Endpoints";
import AddToCartModal from "./AddToCartModal";
import { parseAmount } from "../Services/Helpers";

const OrderCustomer = (props) => {
  const { item, updateCart, productList } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductAddLoading, setIsProductAddLoading] = useState(false);
  const [weightAttributeValue, setWeightAttributeValue] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsProductAddLoading(false);
  };

  useEffect(() => {
    // console.log("item right", item.attributes.attribute);
    if (item.attributes.attribute) {
      const weightAttribute = item.attributes.attribute.find(
        (attr) => attr["order.product.attribute.code"] === "weight"
      );
      // console.log(weightAttribute);
      let dummyAttribute = weightAttribute
        ? setWeightAttributeValue(
            weightAttribute["order.product.attribute.value"]
          )
        : null;
      console.log(dummyAttribute);
    }
  }, []);

  const openModel = () => {
    setIsModalOpen(true);
  };

  const handleRemoveCart = (e, item) => {
    e.preventDefault();
    fetch(API_GET_CART + "?id=default&related=product&relatedid=" + item, {
      method: "DELETE",
      crossDomain: true,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("cart", data);
        updateCart(data);
      });
  };

  return (
    <>
      <div className="table-booking" onClick={() => openModel()}>
        <div className="card-style-booking">
          {/* <div className="order-booking">
            <img
              src={props.orderDishesImage}
              alt=""
              className=" product-image"
            />
          </div> */}
          <div>
            <div className="order-confirm">
              <h3 className="bill-heading-confirm">{props.heading}</h3>
              {/* <p className="sub-heading">{props.subHeading}</p> */}
              <div className="list-customer-items">
                <span className="label">{props.rebate}</span>
                <span className="size">{props.Size}</span>
              </div>
              <div className="list-customer-items">
                <span className="label">{props.Ica}</span>
                <span className="size">{props.Less}</span>
              </div>
            </div>
          </div>
          <div className="delete-items"></div>
        </div>
        <div className="button-order">
          <div className="food-select">
            <button className="center-button">
              x {item.attributes["order.product.quantity"]}
            </button>
            {item.attributes.attribute ? (
              <small className="weightValue">
                <b>Weight:</b> {weightAttributeValue}
              </small>
            ) : null}
          </div>

          <div className="order-confirm">
            <p className="sub-heading">
              {props.rebate < 0 && (
                <del>
                  $
                  {parseAmount(
                    parseFloat(props.price) - parseFloat(props.rebate)
                  )}
                </del>
              )}
              ${props.price}
            </p>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <AddToCartModal
          title={props.heading}
          price={props.subHeading}
          item={item}
          productList={productList}
          onClose={closeModal}
          fromCart={true}
          handleReloadCart={updateCart}
        />
      )}
    </>
  );
};

export default OrderCustomer;
