// import { useState } from "react";
import { React, useEffect, useState } from "react";
import { API_GET_CART, API_GET_PRODUCTS, API_ME } from "../Config/Endpoints";
import {
  ShowToast,
  getProductDetailsMultiple,
  getProductText,
} from "../Services/Helpers";
import { getUserData, logoutUser } from "../Services/Storage";
import MyLoader from "./MyLoader";
let userData = getUserData();

const AddToCartModal = (props) => {
  const {
    item,
    handleReloadCart,
    productList,
    onClose,
    title,
    price,
    fromCart,
    description,
  } = props;
  const [count, setCount] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [productIsCustomPrice, setProductIsCustomPrice] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [productAttributes, setProductAttributes] = useState({
    price: false,
    weight: false,
  });
  const [currentProduct, setCurrentProduct] = useState({
    attributes: [],
    included: [],
  });
  const [cartProduct, setCartProduct] = useState({ attributes: [] });
  const [isProductAddLoading, setIsProductAddLoading] = useState(false);
  const [cart, setCart] = useState({
    products: [],
    details: { attributes: [] },
    cart: [],
  });
  const [priceAttributeValue, setPriceAttributeValue] = useState("");
  const [weightAttributeValue, setWeightAttributeValue] = useState("");

  const closeModal = () => {
    setIsModalOpen(false);
    setCount(1);
    setIsProductAddLoading(false);
  };

  const openModel = () => {
    setIsModalOpen(true);
    setCount(1);
  };

  const checkAuth = () => {
    fetch(API_ME, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          logoutUser();
        }
        return response.json();
      })
      .then((data) => {
        // console.log('API em', data);
      });
  };

  useEffect(() => {
    checkAuth();
    fetchCart();
  }, []);

  useEffect(() => {
    let productId;
    if (fromCart) {
      productId = item.attributes["order.product.productid"];
    } else {
      productId = item.id;
    }
    // setIsProductLoading(true);
    // fetchProduct();

    async function fetchProduct() {
      await fetch(
        API_GET_PRODUCTS +
          "?id=" +
          productId +
          "&include=attribute,media,price,product,product/property,text,catalog,supplier,stock",
        {
          method: "GET",
          crossDomain: true,
          credentials: "include",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setCurrentProduct(data);
          setIsProductLoading(false);
        })
        .catch((error) => {
          //////// console.log(error);
        });
    }
    checkIfProductInCart(item);
    checkIfProductIsCustomPrice();
  }, [cart]);

  const checkIfProductIsCustomPrice = () => {
    if (item.relationships?.attribute) {
      const attributeIds = item.relationships.attribute.data.map(
        (item) => item.id
      );

      const attributesList = productList.included.filter((product) => {
        return (
          product.type === "attribute" && attributeIds.includes(product.id)
        );
      });

      const weightAttribute = attributesList.filter(
        (item) =>
          item.type === "attribute" &&
          item.attributes["attribute.type"] === "weight"
      );

      const priceAttribute = attributesList.filter(
        (item) =>
          item.type === "attribute" &&
          item.attributes["attribute.type"] === "price"
      );

      setProductAttributes((prevAttributes) => ({
        ...prevAttributes,
        price: priceAttribute,
        weight: weightAttribute,
      }));
    }

    // const weightAttribute = currentProduct.included.filter(
    //   (item) =>
    //     item.type === "attribute" &&
    //     item.attributes["attribute.type"] === "weight"
    // );

    // console.log("weightAttribute2", weightAttribute);

    // const priceAttribute = currentProduct.included.filter(
    //   (item) =>
    //     item.type === "attribute" &&
    //     item.attributes["attribute.type"] === "price"
    // );

    // setProductAttributes((prevAttributes) => ({
    //   ...prevAttributes,
    //   price: priceAttribute,
    //   weight: weightAttribute,
    // }));
  };

  const fetchCart = async () => {
    console.log("fetching cart");
    await fetch(API_GET_CART + "?id=default", {
      method: "GET",
      crossDomain: true,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        const cartProducts = data.included.filter((product) => {
          return product.type === "basket.product";
        });

        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
          cart: data,
        }));
      })
      .catch((error) => {
        ////// console.log(error);
      });
  };

  const handleAddToCart = async (e, product) => {
    e.preventDefault();
    setIsProductAddLoading(true);
    let data = [
      {
        attributes: {
          "product.id": product.id,
          quantity: count,
          stocktype: "default",
        },
      },
    ];

    if (productAttributes.price.length > 0) {
      const customProperty = {
        [productAttributes.price[0].id]: priceAttributeValue,
      };
      data[0].attributes.custom = customProperty;
    }

    if (productAttributes.weight.length > 0) {
      const weightCustomProperty = {
        [productAttributes.weight[0].id]: weightAttributeValue,
      };
      data[0].attributes.custom = {
        ...data[0].attributes.custom,
        ...weightCustomProperty,
      };
    }

    await fetch(API_GET_CART + "?id=default&related=product", {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          data.errors.forEach((element) => {
            // ...use `element`...
            ShowToast("error", element.title);
          });
          setIsProductAddLoading(false);
        } else {
          handleReloadCart(data);
          onClose();
        }
      })
      .catch((error) => {
        ////// console.log(error);
      });
  };

  const handleUpdateProductCart = async (e, product) => {
    e.preventDefault();
    //////// console.log(product);
    setIsProductAddLoading(true);
    // ////// console.log(product.data.links["basket/product"]["href"]);

    let data = {
      attributes: {
        quantity: count,
      },
    };

    if (productAttributes.price.length > 0) {
      const customProperty = {
        [productAttributes.price[0].id]: priceAttributeValue,
      };
      data.attributes.custom = customProperty;
    }

    if (productAttributes.weight.length > 0) {
      const weightCustomProperty = {
        [productAttributes.weight[0].id]: weightAttributeValue,
      };
      data.attributes.custom = {
        ...data.attributes.custom,
        ...weightCustomProperty,
      };
    }

    await fetch(
      API_GET_CART + "?id=default&&related=product&relatedid=" + product,
      {
        method: "PATCH",
        crossDomain: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          data.errors.forEach((element) => {
            ShowToast("error", element.title);
          });
          setIsProductAddLoading(false);
        } else {
          handleReloadCart(data);
          onClose();
        }
      })
      .catch((error) => {
        ////// console.log(error);
      });
  };

  const handleRemoveCart = async (e, item) => {
    e.preventDefault();
    await fetch(
      API_GET_CART + "?id=default&related=product&relatedid=" + item,
      {
        method: "DELETE",
        crossDomain: true,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        handleReloadCart(data);
        onClose();
      });
  };

  const checkIfProductInCart = (item) => {
    let findItem;
    if (fromCart) {
      findItem = item.attributes["order.product.productid"];
    } else {
      findItem = item.id;
    }

    // console.log("findItem", findItem);
    let product = cart.products.filter(
      (x) => x.attributes["order.product.productid"] == findItem
    );

    let priceValue = null;
    let weightValue = null;
    if (product.length > 0 && product[0].attributes.attribute) {
      const priceAttribute = product[0].attributes.attribute.find(
        (attr) => attr["order.product.attribute.code"] === "price"
      );

      priceValue = priceAttribute
        ? priceAttribute["order.product.attribute.value"]
        : null;
      setPriceAttributeValue(priceValue);

      const weightAttribute = product[0].attributes.attribute.find(
        (attr) => attr["order.product.attribute.code"] === "weight"
      );

      weightValue = weightAttribute
        ? weightAttribute["order.product.attribute.value"]
        : null;
      setWeightAttributeValue(weightValue);
    }

    if (product.length > 0) {
      setCartProduct(product[0]);
      setCount(product[0].attributes["order.product.quantity"]);
      setAlreadyInCart(true);
    }
  };

  return (
    <>
      <>
        <div className="modal-backdrop fade show"></div>
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-card">
              {isProductLoading ? (
                <>
                  {" "}
                  <MyLoader />{" "}
                </>
              ) : (
                <>
                  <div className="modal-header">
                    <div className="">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {title}
                      </h5>
                      {/* {productAttributes.price.length <= 0 && ( */}
                      <>
                        <p className="modal-price sub_title">${price}</p>
                      </>
                      {description}
                      {/* )} */}
                    </div>
                    <div className="food-select">
                      <button
                        className="first-button"
                        onClick={() =>
                          count <= 1 ? null : setCount(count - 1)
                        }
                      >
                        -
                      </button>
                      <button className="center-button center-count-btn">
                        {count}
                      </button>
                      <button
                        className="first-button"
                        onClick={() => setCount(count + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {productAttributes.price.length > 0 && (
                    <div className="modal-body py-0">
                      <div className="modal-outer-text">
                        <div className="size-select-food">
                          <h5 className="add-on-card">
                            Custom Price & Weight:
                          </h5>
                          <p className="coming-soon">Price</p>
                          <input
                            placeholder="Enter Price"
                            className="form-control"
                            type="text"
                            value={priceAttributeValue}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const numericValue = inputValue.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setPriceAttributeValue(numericValue);
                            }}
                          />
                          <p className="coming-soon">Weight</p>
                          <input
                            placeholder="Enter Weight"
                            className="form-control"
                            type="text"
                            value={weightAttributeValue}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const numericValue = inputValue.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setWeightAttributeValue(numericValue);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="modal-body py-0">
                    <div className="modal-outer-text">
                      <div className="size-select-food">
                        <h3 className="add-on-card">Add on:</h3>
                        <p className="coming-soon">Coming Soon</p>
                        <div className="button-radio"></div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer modal-button-footer">
                    <h4 className="add-items">Add Item</h4>
                    <div className="confirm">
                      {alreadyInCart && (
                        <>
                          {" "}
                          <img
                            src="../../assets/images/delete.png"
                            alt=""
                            onClick={(e) => handleRemoveCart(e, cartProduct.id)}
                          />
                        </>
                      )}
                      <button
                        type="button"
                        className="btn theme-black close-search"
                        data-bs-dismiss="modal"
                        onClick={onClose}
                      >
                        Close
                      </button>
                      {alreadyInCart ? (
                        <button
                          type="button"
                          className="btn theme-black"
                          onClick={(e) =>
                            handleUpdateProductCart(e, cartProduct.id)
                          }
                        >
                          {isProductAddLoading && (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          )}{" "}
                          Update
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn theme-black"
                          onClick={(e) => handleAddToCart(e, item)}
                        >
                          {isProductAddLoading && (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          )}{" "}
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default AddToCartModal;
