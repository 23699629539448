import React, { useEffect, useState } from "react";

import {
  API_CANCEL_TERMINAL,
  API_MOVE_TO_CASH,
  API_ORDER_CAPTURE_PAYMENT_INTENT,
  API_ORDER_CONNECTION_TOKEN,
  API_ORDER_PAYMENT_INTENT,
  API_ORDER_UPDATE,
} from "../Config/Endpoints";
import { getUserData } from "./Storage";
import { ShowToast } from "./Helpers";

let userData = getUserData();
const StripeTerminalComponent = (props) => {
  const { orderId, amount, onPayment } = props;
  const [devices, setDevices] = useState([]);
  const [showCashBtn, setShowCashBtn] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(false);
  const [currentStep, setCurrentStep] = useState({
    step: 1,
    msg: "Proccessing..",
    status: true,
    details: "",
  });
  const [change, setChange] = useState(0);

  var terminal = StripeTerminal.create({
    onFetchConnectionToken: fetchConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });

  var discoveredReaders;
  var paymentIntentId;
  var chargeDetails;
  //   var orderId;
  var chargeAmount;

  async function unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log("Disconnected from reader");
  }

  async function fetchConnectionToken() {
    console.log("=========1-FETCH=============");
    setCurrentStep({ step: 1, msg: "Connecting..", status: true });
    const response = await fetch(API_ORDER_CONNECTION_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
    });
    const data = await response.json();
    console.log("fetchConnectionToken", data.secret);
    if (!data.secret) {
      setCurrentStep({ step: 1, msg: "Failed Connecting..", status: false });
    }
    return data.secret;
  }

  async function discoverReaderHandler() {
    console.log("=========2-DISCOVER=============");
    setCurrentStep({ step: 2, msg: "Discovering Devices", status: true });
    const config = {
      // simulated: Boolean(process.env.REACT_APP_TERMINAL_SIMULATED),
      simulated: process.env.REACT_APP_ENV === "production" ? false : true,
      location: process.env.REACT_APP_TERMINAL_LOCATION,
    };
    await terminal
      .discoverReaders(config)
      .then(async function (discoverResult) {
        if (discoverResult.error) {
          console.log("Failed to discover: ", discoverResult.error);
          setCurrentStep({ step: 2, msg: "Failed to discover", status: false });
        } else if (discoverResult.discoveredReaders.length === 0) {
          console.log("No available readers.");
          //   setCurrentStepMsg("No available readers.");
          setCurrentStep({
            step: 2,
            msg: "No available readers",
            status: false,
          });
        } else {
          console.log("discoverResult", discoverResult.discoveredReaders);
          discoveredReaders = discoverResult.discoveredReaders;
          setDevices(discoverResult.discoveredReaders);
          console.log("terminal.discoverReaders", discoveredReaders);
          setCurrentStep({
            step: 2,
            msg: "Discovered Device, Please select device to connect..",
            status: true,
          });
          //   setCurrentStepMsg("Discovered Device, Connecting..");
          // console.log("==Connecting Reader==");
          // await connectReaderHandler(discoveredReaders);
        }
      });
  }

  const handleDeviceSelection = async (key) => {
    console.log("==Connecting Reader==");
    await connectReaderHandler(devices, key);
  };

  async function connectReaderHandler(discoveredReaders, key) {
    // Just select the first reader here.
    console.log("connecting", discoveredReaders);
    console.log("=========3-CONNECTION=============");
    var selectedReader = discoveredReaders[key];
    console.log("reder", selectedReader);
    setSelectedDevice(selectedReader);
    await terminal
      .connectReader(selectedReader)
      .then(async function (connectResult) {
        if (connectResult.error) {
          console.log("Failed to connect: ", connectResult.error);
          //   setCurrentStepMsg("Failed to connect");
          setCurrentStep({
            step: 3,
            msg: "Failed to connect",
            details: connectResult.error.message,
            status: false,
          });
        } else {
          console.log("Connected to reader: ", connectResult.reader.label);
          console.log("terminal.connectReader", connectResult);
          //   setCurrentStepMsg("Connected To device");
          setCurrentStep({
            step: 3,
            msg: "Connected To device",
            status: true,
          });
          console.log("chargeAmount====", amount);
          await collectPayment(amount);
        }
      });
  }

  async function fetchPaymentIntentClientSecret(amount) {
    const bodyContent = JSON.stringify({
      order_id: orderId,
      amount: amount,
      description: "POS ORDER ID = " + orderId,
    });
    return await fetch(API_ORDER_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: bodyContent,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.client_secret;
      });
  }

  async function collectPayment(amount) {
    console.log("=========4-COLLECT=============");
    // setCurrentStepMsg("Collecting payment");
    setCurrentStep({
      step: 4,
      msg: "Collecting payment",
      status: true,
    });
    await fetchPaymentIntentClientSecret(amount).then(async function (
      client_secret
    ) {
      await terminal.setSimulatorConfiguration({
        testCardNumber: "4242424242424242",
      });
      console.log("client_secret", client_secret);
      console.log("terminal before collectPaymentMethod", terminal);
      await terminal
        .collectPaymentMethod(client_secret)
        .then(async function (result) {
          if (result.error) {
            // Placeholder for handling result.error
            // setCurrentStepMsg("Failed payment");
            setCurrentStep({
              step: 4,
              msg: "Failed payment",
              status: false,
              details: result.error.message,
            });
          } else {
            console.log("terminal.collectPaymentMethod", result.paymentIntent);
            let paymentIntentId = result.paymentIntent;
            await terminal
              .processPayment(result.paymentIntent)
              .then(async function (result) {
                if (result.error) {
                  console.log("result", result);
                  //   setCurrentStepMsg("Failed payment");
                  setCurrentStep({
                    step: 4,
                    msg: "Failed payment",
                    status: false,
                    details: result.error.message,
                  });
                  // paymentIntentId = result.paymentIntent.id;
                  // console.log("terminal.processPayment", result.paymentIntent);
                  // await capture(paymentIntentId.id);
                } else if (result.paymentIntent) {
                  paymentIntentId = result.paymentIntent.id;
                  console.log("terminal.processPayment", result.paymentIntent);
                  await capture(paymentIntentId);
                }
              });
          }
        });
    });
  }

  async function capture(paymentIntentId) {
    console.log("paymentIntentId", paymentIntentId);
    return await fetch(API_ORDER_CAPTURE_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        payment_intent_id: paymentIntentId,
        order_id: orderId,
      }),
    })
      .then(function (response) {
        chargeDetails = response.json();
        // setCurrentStepMsg("Payment Successfull");
        setCurrentStep({
          step: 5,
          msg: "Payment Successfull",
          status: true,
        });
        onPayment(chargeDetails);
        return chargeDetails;
      })
      .then(function (data) {
        // setCurrentStepMsg("Failed payment");
        setCurrentStep({
          step: 5,
          msg: "Payment Successfull",
          status: true,
        });
        onPayment(data);
        console.log("server.capture", data);
      });
  }

  const init = async (orderId, amount) => {
    console.log("terminal", orderId);
    chargeAmount = amount;
    orderId = orderId;

    // console.log("INIT Terminal orderId", orderId);
    // console.log("INIT Terminal amount", chargeAmount);

    await discoverReaderHandler();
    console.log("returning", chargeDetails);
    return chargeDetails;
    //   await connectReaderHandler(discoveredReaders);
  };

  const handlePayByCash = async () => {
    console.log("By cash");
    await fetch(API_MOVE_TO_CASH, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        order_id: orderId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("orderupdate", data);
        setCurrentStep({
          step: 5,
          msg: "Cash Payment Successfull",
          status: true,
        });
        onPayment(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCalculateChange = (value, total) => {
    const cashFromCustomer = parseFloat(value);
    const totalAmount = total;
    const calculatedChange = isNaN(cashFromCustomer)
      ? 0
      : (cashFromCustomer - totalAmount).toFixed(2);
    setChange(calculatedChange);
  };

  const handleDeviceConnection = async () => {
    console.log(selectedDevice);
    await fetch(API_CANCEL_TERMINAL, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        terminal_id: selectedDevice.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("orderupdate", data);
        if (data.success) {
          setCurrentStep({
            step: 4,
            msg: "Card Payment Canceled",
            status: false,
          });
        } else {
          ShowToast("error", data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("Stripe Terminal");
    // console.log("Stripe Terminal orderId", orderId);
    // console.log("Stripe Terminal amount", amount);
    init(orderId, amount);
  }, [orderId]);

  return (
    <>
      {/* <h1>Stripe</h1> */}
      <div class="alert alert-success" role="alert">
        {currentStep.status ? (
          <i class="fa fa-circle-o-notch fa-2x fa-spin" aria-hidden="true"></i>
        ) : null}
        {currentStep.msg}
      </div>
      {!currentStep.status ? (
        <>
          <div class="alert alert-danger" role="alert">
            {currentStep.details}
          </div>
          <div class="alert alert-warning" role="alert">
            Please select device again to retry
          </div>
        </>
      ) : null}
      <div className="device-button">
        {devices.map((item, key) => (
          <>
            <button
              key={key}
              onClick={() => handleDeviceSelection(key)}
              className="visible-button-1 card-btn"
            >
              {item.label}
            </button>
          </>
        ))}

        {currentStep.step === 4 ? (
          <>
            <button
              onClick={() => handleDeviceConnection()}
              className="visible-button-1 card-btn"
            >
              Void Transaction
            </button>
          </>
        ) : null}

        {!showCashBtn ? (
          <button
            // key={key}
            // onClick={() => handlePayByCash()}
            onClick={() => setShowCashBtn(true)}
            className="visible-button-1 card-btn"
          >
            Pay by Cash
          </button>
        ) : null}
      </div>{" "}
      {showCashBtn ? (
        <>
          <div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2">
                  Total Amount : ${amount}
                </span>
              </div>
              <input
                type="text"
                class="form-control mtop0"
                placeholder="Cash from Customer"
                onChange={(e) => handleCalculateChange(e.target.value, amount)}
              />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">
                  Return : ${change}
                </span>
              </div>
            </div>
          </div>
          <button
            // key={key}
            onClick={() => handlePayByCash()}
            className="visible-button-1 card-btn"
          >
            Pay by Cash
          </button>
        </>
      ) : null}
      <div className="payment-msg text-center">
        {/* <i class="fa fa-circle-o-notch fa-2x fa-spin" aria-hidden="true"></i>
        <h5>{currentStep.msg}</h5> */}

        {/* {!currentStep.status ? <p>{currentStep.details}</p> : null}
        {!currentStep.status ? "Retry" : null} */}
      </div>
    </>
  );
};

export default StripeTerminalComponent;
