import React from "react";
import MainLayout from "../Layout/MainLayout";
import AvailableTableOrderTime from "../Components/AvailableTableOrderTime";

const AvailableTable = () => {
  return (
    <>
      <MainLayout>
        <div className="kitchen-items-outer">
          <div className="available-table">
            <div className="available">
              <h3>Available Table (19/20)</h3>
              <div className="all-food-timing">
                <img src="../../assets/images/circle-gray.png" alt="" />
                <span>Completed</span>
                <img src="../../assets/images/circle-orange.png" alt="" />
                <span>Upcoming</span>
                <img src="../../assets/images/circle-green.png" alt="" />
                <span>In-progress</span>
              </div>
            </div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Today's"
                aria-describedby="basic-addon1"
              />
              <span className="input-group-text" id="basic-addon1">
                <img
                  src="../../assets/images/calendar.png"
                  alt="calendar"
                  className="img-fluid"
                />
              </span>
            </div>
          </div>
          <div className="timing-table">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/minus.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-6">
                  <AvailableTableOrderTime
                    Table="TABLE"
                    Count="01"
                    Max="Max 4 pax"
                    CriCle="../../assets/images/right.png"
                    Time="08:00am"
                    Mins="-"
                    ImagesCriCleGray=""
                    Time2="09:00am"
                    Rese="Rese..."
                    ImagesCriCle="../../assets/images/circle-gray.png"
                    Time3="10:00am"
                    Mins3="-"
                    ImagesCriCle3=""
                    Time4="11:00am"
                    Mins4="-"
                    ImagesCriCle4=""
                    Time5="12:00am"
                    Mins5="-"
                    ImagesCriCle5=""
                    Time6="01:00pm"
                    Mins6="-"
                    ImagesCriCle6=""
                    Time7="02:00pm"
                    Mins7="-"
                    ImagesCriCle7=""
                    Time8="03:00pm"
                    Mins8="-"
                    ImagesCriCle8=""
                    Time9="04:00pm"
                    Mins9="-"
                    ImagesCriCle9=""
                    TimePro1="05:00pm"
                    MinsPro1="Rese..."
                    ImagesCriClePro1="../../assets/images/circle-orange.png"
                    TimePro2="06:00pm"
                    MinsPro2="Rese..."
                    ImagesCriClePro2="../../assets/images/circle-orange.png"
                    Customer="07:00pm"
                    List="-"
                    ImageCon=""
                    Customer1="08:00pm"
                    List1="-"
                    ImageCon1=""
                    Customer2="09:00pm"
                    List2="-"
                    ImageCon2=""
                    Customer3="10:00pm"
                    List3="-"
                    ImageCon3=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AvailableTable;
