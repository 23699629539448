import React from 'react'

const TableNumber = (props) => {
    return (
        <>
            <div className="outer-table-list">
                <h2>
                    {props.TableCard}
                </h2>
                <p>
                    {props.TableCardId}
                </p>
                <span>
                    {props.TableCardIn}
                </span>
                <p>
                    {props.TableCardTime}
                </p>
            </div>
        </>
    )
};

export default TableNumber;
