import React from 'react'

const DisableCard = (props) => {
    return (
        <>
            <div className="disable-card">
                <div className="dis">
                    <div className="cover">
                        <img src="../../assets/images/category-1.jpg" alt="" className="img-fluid" />
                        <h4>{props.DisableHeading} </h4>
                    </div>
                </div>
                <div className="dis-line">
                    <div className="outer-kitchen">
                        <h3>
                            {props.DisableItem}
                        </h3>
                        <h4>
                            {props.DisableNumber}
                        </h4>
                    </div>
                    <span>
                        {props.DisableSize}
                    </span>
                    <span>
                        {props.DisableCheese}
                    </span>
                    <button className="visible-button">Complete</button>
                    <button className="not-visible-button">Cancel</button>
                </div>
            </div>
        </>
    )
};

export default DisableCard;