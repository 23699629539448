import React, { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import { Link } from "react-router-dom";
import PriceCard from "../Components/PriceCard";
import { API_ORDER_LIST, API_REFUND } from "../Config/Endpoints";
import MyListLoader from "../Components/MyListLoader";
import {
  ShowToast,
  getFirstNameFromOrderId,
  getMediaUrlFromString,
  getOrderPaymentStatus,
  getProductDetailsMultiple,
} from "../Services/Helpers";
import { getUserData, logoutUser } from "../Services/Storage";

let userData = getUserData();
const HistoryOrder = () => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [orderList, setOrderList] = useState({
  //   items: [],
  //   pagination: { total: 0 },
  // });
  const [orderList, setOrderList] = useState([]);
  const [orderListComplete, setOrderListComplete] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrder, setCurrentOrder] = useState(false);
  const [currentOrderProducts, setCurrentOrderProducts] = useState([]);
  const [pageArray, setPageArray] = useState([]);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterOrderPlace, setFilterOrderPlace] = useState("pos");
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [isRefundModal, setIsRefundModal] = useState(false);
  const [refundOrder, setRefundOrder] = useState(false);
  const [refundAmount, setRefundAmount] = useState(false);
  const [onFliterClick, setOnfliterClick] = useState(false);

  const handleRefund = async (item) => {
    const confirmRefund = window.confirm(
      `Are you sure you want to refund order id: ${item["order.id"]}?`
    );

    if (confirmRefund) {
      // setLoadingRefund((prevState) => ({
      //   ...prevState,
      //   [item["order.id"]]: true,
      // }));

      setLoadingRefund(true);

      await fetch(API_REFUND + "?id=default", {
        method: "POST",
        crossDomain: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + userData.token,
        },
        body: JSON.stringify({
          order_id: item["order.id"],
          refund_base_amount: refundAmount,
          // refund_tax_amount: 0.2,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoadingRefund(false);
          if (!data.success) {
            ShowToast("error", data.message);
            return;
          }
          setIsRefundModal(false);
          fetchOrderList();
          ShowToast("success", "Refund initated successfully");
          // window.location = "";
        })
        .catch((error) => {
          console.log(error);
          setLoadingRefund(false);
        });

      // Make the API request for refund
      // Once the request is complete, set the loading state back to false

      // Example code using setTimeout to simulate API request
    }
  };

  useEffect(() => {
    if (!userData.isLoggedIn) {
      window.location = "/login";
    }
  });

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    setEndDate(selectedDate);
  };

  useEffect(() => {
    fetchOrderList();
  }, [currentPage, limit]);

  const handleFilterViaDate = () => {
    setIsLoading(true);
    setOnfliterClick(false);
    // if (startDate === "" || endDate === "") {
    //   ShowToast("error", "Please select Start Date and End Date");
    //   return false;
    // }
    if (startDate > endDate) {
      ShowToast("error", "Start date cannot be greater than end date");
    } else {
      const start_date = startDate + " 00:00:00";
      const end_date = endDate + " 12:00:00";
      let filterDateString = "";
      let filterString =
        filterOrderPlace === "all"
          ? ""
          : `filter[%26%26][][%3D%3D][order.order_place_type]=${filterOrderPlace}`;
      if (startDate !== "" && endDate !== "") {
        const filterDateString = `&&filter[%26%26][][%3E%3D][order.ctime]=${start_date}&&filter[%26%26][][%3C%3D][order.ctime]=${end_date}`;
        filterString = filterString + filterDateString;
      }
      fetch(
        API_ORDER_LIST +
          `?${filterString}&page=${currentPage}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`,
        {
          method: "GET",
          crossDomain: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + userData.token,
          },
        }
      )
        .then((response) => {
          if (response.status === 401) {
            // alert('Unauthorized access!');
            console.log();
            if (userData.isLoggedIn) {
              logoutUser();
            }
          }
          return response.json();
        })
        .then((data) => {
          console.log("orderList", data);
          setOrderListComplete(data);
          setOrderList(data.data);
          setIsLoading(false);
          console.log(orderList);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const fetchOrderList = () => {
    setIsLoading(true);
    // fetch(API_ORDER_LIST + `?page=${currentPage}&limit=${limit}`, {
    //&filter[%3E%3D][order.ctime]=1686732590
    fetch(
      API_ORDER_LIST +
        `?page[offset]=${
          currentPage == 1 ? 0 : currentPage * limit
        }&page[limit]=${limit}&include=order/address,order/product`,
      {
        method: "GET",
        crossDomain: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + userData.token,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          // alert('Unauthorized access!');
          console.log();
          if (userData.isLoggedIn) {
            logoutUser();
          }
        }
        return response.json();
      })
      .then((data) => {
        console.log("orderList", data.data);
        setOrderListComplete(data);
        setOrderList(data.data);
        setIsLoading(false);
        console.log(orderList);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // if (orderList.pagination.total && orderList.pagination.limit) {
    //   const totalPages = Math.ceil(
    //     orderList.pagination.total / orderList.pagination.limit
    //   );
    //   setPageArray(Array.from(Array(totalPages).keys()));
    // }
  }, [orderList]);

  const handlePageChange = (direction, e) => {
    e.preventDefault();
    let newPage = currentPage;
    if (direction === "next" && currentPage < orderListComplete.meta.total) {
      newPage = currentPage + 1;
    } else if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    }
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const showOrder = (e, item) => {
    e.preventDefault();
    console.log("Order Item", item);
    setShow(true);

    console.log("Order products", item.relationships["order/product"].data);
    let producIds = item.relationships["order/product"].data.map((x) => {
      return x.id;
    });
    console.log("Order products Ids", producIds);

    let productList = orderListComplete.included.filter((x) =>
      producIds.some((id) => x.id.includes(id))
    );
    console.log("Order productList", productList);
    setCurrentOrderProducts(productList);
    setCurrentOrder(item);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  const handleRefundAmountChange = (e) => {
    setRefundAmount(e.target.value);
  };

  const showAndroidPrintToast = (orderDetails, list) => {
    // console.log(orderListComplete);
    // console.log(orderDetails.relationships);
    const productIds = orderDetails.relationships["order/product"].data.map(
      (obj) => obj.id
    );
    // console.log(productIds);
    const cartProducts = orderListComplete.included.filter((product) => {
      return (
        product.type === "order/product" && productIds.includes(product.id)
      );
    });

    const print = JSON.stringify({
      item: orderDetails,
      products: cartProducts,
    });

    console.log("print", print);
    printOrder.postMessage(print);
  };

  const onFliterClickHandle = () => {
    setOnfliterClick(true);
  };

  return (
    <>
      <MainLayout onFliterClick={onFliterClickHandle}>
        <div className="customer-dishes">
          <div className="customer-food-select">
            <div className="table-dishes">
              <div className={`filter ${onFliterClick ? "show" : ""}`}>
                <div className="input-date-picker">
                  <h3 className="filter_order">
                    Filter
                    <a
                      className="filter-close"
                      href="javascript:;"
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                        setOnfliterClick(false);
                        setFilterOrderPlace("all");
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </a>
                  </h3>

                  <div className="mode">
                    <label>Order Mode</label>
                    <select
                      className="form-select select-input"
                      onChange={(e) => setFilterOrderPlace(e.target.value)}
                      value={filterOrderPlace}
                    >
                      <option selected value={"all"}>
                        All
                      </option>
                      <option value={"POS"}>POS</option>
                      <option value={"WEBSITE"}>Website</option>
                    </select>
                  </div>
                  <div className="date-picker">
                    {/* <p className="date-required">Date Range:</p> */}
                    <div className="filter-date">
                      <label htmlFor="start-date">From Date</label>
                      <input
                        type="date"
                        id="start-date"
                        className="form-control"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div className="filter-date">
                      <label htmlFor="end-date">To Date</label>
                      <input
                        type="date"
                        id="end-date"
                        className="form-control"
                        value={endDate}
                        max={getCurrentDate()}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div className="clear-go">
                    <a
                      className="hand-right"
                      onClick={() => handleFilterViaDate()}
                    >
                      Apply
                    </a>
                    <a
                      className="clear-btn"
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                        fetchOrderList();
                        setOnfliterClick(false);
                        setFilterOrderPlace("all");
                      }}
                    >
                      Clear
                    </a>
                  </div>
                </div>
              </div>{" "}
              {orderList?.length === 0 && !isLoading && (
                <h4 className="filter-order-list">NO RESULTS FOUND</h4>
              )}
              <div className="table-responsive">
                <table className="table  table-hover table-set">
                  <thead className="thead-default">
                    <tr>
                      <th>Order ID</th>
                      <th>Customer</th>
                      <th>Order Date</th>
                      <th>Amount</th>
                      <th>Tip</th>
                      <th>Payment Mode</th>
                      <th>Status</th>
                      <th>Order Type</th>
                      <th>Delivery Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <>
                      <tbody className="tbody-default">
                        <tr>
                          <td colSpan={10}>
                            <MyListLoader />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  ) : (
                    <>
                      <tbody className="tbody-default">
                        {orderList.map((item, key) => (
                          <>
                            <tr key={key}>
                              <td>
                                <Link
                                  to="#"
                                  onClick={(e) => showOrder(e, item)}
                                >
                                  #{item["id"]}
                                </Link>
                              </td>
                              <td>
                                {getFirstNameFromOrderId(
                                  orderListComplete,
                                  item?.relationships["order/address"]?.data[0]
                                    ?.id
                                )}
                              </td>
                              <td>{item.attributes["order.ctime"]}</td>
                              <td>${item.attributes["order.final_amount"]}</td>
                              <td>
                                {item.attributes["tip_amount"]
                                  ? `$${item.attributes["tip_amount"]}`
                                  : "-"}
                              </td>
                              <td>{item.attributes["payment_method"]}</td>
                              <td>
                                <p className={`status success`}>
                                  {getOrderPaymentStatus(
                                    item.attributes["order.statuspayment"]
                                  )}
                                </p>
                              </td>
                              <td>{item.attributes["order_place_type"]}</td>
                              <td>{item.attributes["order_type"]}</td>
                              <td>
                                <p className="refund-items">
                                  <a
                                    href="javascript:;"
                                    className="refund-btn"
                                    onClick={() => showAndroidPrintToast(item)}
                                  >
                                    <img
                                      src="../assets/images/printer-full.png"
                                      alt=""
                                      style={{ width: "20px" }}
                                    />
                                  </a>
                                  {item.attributes["order.statuspayment"] ===
                                  6 ? (
                                    <a
                                      href="javascript:;"
                                      className="refund-btn"
                                      onClick={() => {
                                        setRefundOrder(item);
                                        setRefundAmount(
                                          item.attributes["order.final_amount"]
                                        );
                                        setIsRefundModal(true);
                                      }}
                                    >
                                      Refund
                                    </a>
                                  ) : null}{" "}
                                </p>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
              <div className="pagination">
                <div className="show-2">
                  Show
                  <select
                    className="select-input width"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => handlePageChange("prev", e)}
                    >
                      &laquo; Prev
                    </a>
                  </li>
                  {pageArray.map((page) => (
                    <li key={page}>
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => {
                          setCurrentPage(page + 1);
                          e.preventDefault();
                        }}
                        className={currentPage === page + 1 ? "active" : ""}
                      >
                        {page + 1}
                      </a>
                    </li>
                  ))}

                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => handlePageChange("next", e)}
                    >
                      Next &raquo;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {show && (
              <div className="price-times-select">
                <h3 className="select-heading">
                  Order:<span>#{currentOrder.attributes["order.id"]}</span>
                  <a style={{ float: "right" }} onClick={() => setShow(false)}>
                    <img src="../../assets/images/close.png" alt="" />
                  </a>
                </h3>
                <div className="food-scroll">
                  {currentOrderProducts.map((item, key) => (
                    <PriceCard
                      CardImages={getMediaUrlFromString(
                        item.attributes["order.product.mediaurl"]
                      )}
                      heading={item.attributes["order.product.name"]}
                      price={`$${item.attributes["order.product.price"]}`}
                      Quantity={`Qty: ${item.attributes["order.product.quantity"]}`}
                    />
                  ))}
                </div>

                <div className="subtotal">
                  <div className="taxes">
                    <p>Subtotal</p>
                    <h3>${currentOrder.attributes["order.price"]}</h3>
                  </div>
                  <div className="pye-bile">
                    <p>Taxes</p>
                    <h3>
                      $
                      {parseFloat(
                        currentOrder.attributes["order.taxvalue"]
                      ).toFixed(2)}
                    </h3>
                  </div>
                  <div className="pye-bile">
                    <p>Tip</p>
                    <h3>
                      $
                      {parseFloat(
                        currentOrder.attributes["tip_amount"]
                      ).toFixed(2)}
                    </h3>
                  </div>
                  <div className="customer-pye-bill">
                    <p>Total</p>
                    <h3>
                      ${" "}
                      {parseFloat(
                        currentOrder.attributes["order.final_amount"]
                      ).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {isRefundModal && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal fade show"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content p-4 pt-5">
                  <table className="table table-bordered">
                    <tr>
                      <th>Order ID</th>
                      <td>#{refundOrder["order.id"]}</td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>
                        {refundOrder.address[0]["order.address.firstname"]}
                      </td>
                    </tr>
                    <tr>
                      <th>Time</th>
                      <td>{refundOrder["order.ctime"]}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>${refundOrder["order.final_amount"]}</td>
                    </tr>
                    <tr>
                      <th>Tip</th>
                      <td>
                        {refundOrder["tip_amount"]
                          ? `$${refundOrder["tip_amount"]}`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Method</th>
                      <td>{refundOrder["payment_method"]}</td>
                    </tr>
                    <tr>
                      <th>Payment Status</th>
                      <td>
                        {getOrderPaymentStatus(
                          refundOrder["order.statuspayment"]
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Order Place</th>
                      <td>{refundOrder["order_place_type"]}</td>
                    </tr>
                    <tr>
                      <th>Order Type</th>
                      <td>{refundOrder["order_type"]}</td>
                    </tr>
                    <tr>
                      <th>Refund Amount</th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          onChange={(e) => handleRefundAmountChange(e)}
                          value={refundAmount}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic outlined example"
                        >
                          <button
                            type="button"
                            class="verification continue-btn-order-sm"
                            onClick={() => handleRefund(refundOrder)}
                          >
                            Refund{" "}
                            {loadingRefund ? (
                              <i className="fa fa-spin fa-spinner"></i>
                            ) : null}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <a
                    type="button"
                    class="close-btn"
                    onClick={(e) => setIsRefundModal(false)}
                  >
                    <img src="../../assets/images/close.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </MainLayout>
    </>
  );
};

export default HistoryOrder;
