import React from "react";
import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={600}
    height={460}
    viewBox="0 0 600 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="33" cy="301" r="15" />
    <rect x="60" y="288" rx="2" ry="2" width="141" height="10" />
    <rect x="60" y="305" rx="2" ry="2" width="141" height="10" />
    <rect x="5" y="58" rx="2" ry="2" width="218" height="218" />
    <circle cx="267" cy="301" r="15" />
    <rect x="294" y="288" rx="2" ry="2" width="141" height="10" />
    <rect x="294" y="305" rx="2" ry="2" width="141" height="10" />
    <rect x="246" y="58" rx="2" ry="2" width="218" height="218" />
  </ContentLoader>
);

export default MyLoader;
