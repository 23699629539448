import React from "react";
import MainLayout from "../Layout/MainLayout";
import StockCard from "../Components/StockCard";

const StockProduct = () => {
  return (
    <>
      <MainLayout>
        <div className="kitchen-items-outer">
          <div className="container-fluid">
            <div className="row g-1">
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <StockCard
                  StockImages="../../assets/images/category-4.jpg"
                  StockHeading="Grill Chicken Chop..."
                  StockTitle="chicken, egg, mushroom..."
                  Update="Stock:"
                  StockRight="Availability:"
                />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default StockProduct;
