import { React, useEffect, useState } from "react";
import CardFood from "../Components/CardFood";
import LeftSideBar from "../Components/LeftSideBar";
import RightBar from "../Components/RightBar";
import Menu from "../Components/Menu";
import { Link } from "react-router-dom";
import BoxFood from "../Components/BoxFood";
import { API_GET_CART, API_GET_PRODUCTS } from "../Config/Endpoints";
import {
  getCategoryDetailsMultiple,
  getProductDetailsMultiple,
  ShowToast,
} from "../Services/Helpers";
import MyLoader from "../Components/MyLoader";
import { getUserData } from "../Services/Storage";
const loggedInUser = getUserData();

const Dishes = () => {
  const [layout, setLayout] = useState("list");
  const [selectedCategoryId, setSelectedCategoryId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState({ data: [] });
  const [reloadCart, setReloadCart] = useState({});
  const [currentCart, setCurrentCart] = useState({});
  const [barcodeScan, setBarcodeScan] = useState("");
  const [displayBarcode, setDisplayBarcode] = useState("No Barcode scanned");

  const handleGridLayout = (layout) => {
    setLayout(layout);
  };

  useEffect(() => {
    if (!loggedInUser.isLoggedIn) {
      window.location = "/login";
    }
  });

  useEffect(() => {
    //console.log(reloadCart);
  }, [reloadCart]);

  const handleCategoryChange = (categoryId) => {
    ////console.log(categoryId);
    setSelectedCategoryId(categoryId);
  };

  const handleReloadCart = (cart) => {
    setReloadCart(cart);
  };

  useEffect(() => {
    fetchProducts(selectedCategoryId);
  }, [selectedCategoryId]);

  const fetchProducts = (categoryId) => {
    fetch(
      API_GET_PRODUCTS +
        "?page[offset]=0&page[limit]=200&filter[%26%26][][%21%3D][product.mode]=WEBSITE&filter[f_catid]=" +
        categoryId +
        "&include=attribute,media,price,product,product/property,text,catalog,supplier,stock&sort=product%2Elabel",
      {
        method: "GET",
        crossDomain: true,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // const inStockProducts = data.data.filter((product) => {
        //   // ////console.log(product.attributes['product.instock']);
        //   return product.attributes["product.instock"] === 1;
        // });
        // console.log(data);
        setProductList(data);
        setIsLoading(false);
        // setProductList(data.data);
        // ////console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        ////console.log(error);
      });
  };

  const handleAddToCart = (e, product) => {
    e.preventDefault();
    // ////console.log(product.meta.csrf);
    // ////console.log(product.data.links["basket/product"]["href"]);
    fetch(API_GET_CART + "?id=default&related=product", {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      // body: '{"data": [{\n    "attributes": {\n        "product.id": "1",\n        "quantity": 1,\n        "stocktype": "default"\n    }\n}]}',
      body: JSON.stringify({
        data: [
          {
            attributes: {
              "product.id": product.id,
              quantity: 1,
              stocktype: "default",
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        ////console.log(data.errors);
        if (data.errors) {
          data.errors.forEach((element) => {
            // ...use `element`...
            ShowToast("error", element.title);
          });
        } else {
          // ShowToast("success", "Product is added to cart.");
          //   onCartData(data);
          handleReloadCart(data);
        }

        // setProduct(data);
        // ////console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        ////console.log(error);
      });
  };

  const checkItemExists = (item) => {
    //console.log("cart", reloadCart);
    //console.log("item", item);
  };

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13 && barcodeScan.length > 3) {
        handleScan(e, barcodeScan);
        const itemCardElement = document.querySelector(".close-search");
        if (itemCardElement) {
          itemCardElement.click();
        }
        setBarcodeScan("");
        return;
      }
      if (e.keyCode === 16) {
        return;
      }
      // set Timeout to clear variable
      setTimeout(() => {
        setBarcodeScan("");
      }, 100);

      // Set barcode scan variable
      setBarcodeScan((prevBarcodeScan) => prevBarcodeScan + e.key);
    }

    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcodeScan]);

  function handleScan(e, barcodeString) {
    fetchScannedProduct(e, barcodeString);
  }

  const fetchScannedProduct = (e, code) => {
    fetch(
      API_GET_PRODUCTS +
        "?include=attribute,media,price,product,product/property,text,catalog,supplier,stock&sort=-product.id&filter[%26%26][][%3D%7E][product.code]=" +
        +code,
      {
        method: "GET",
        crossDomain: true,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.data?.length === 0) {
          ShowToast("error", "Scanned product is not available");
        } else if (data?.data?.length === 1) {
          setProductList(data);
          let dummyData = data?.data[0];
          if (dummyData.relationships?.attribute) {
            const attributeIds = dummyData.relationships.attribute.data.map(
              (item) => item.id
            );
            const attributesList = data.included.filter((product) => {
              return (
                product.type === "attribute" &&
                attributeIds.includes(product.id)
              );
            });

            const weightAttribute = attributesList.filter(
              (item) =>
                item.type === "attribute" &&
                item.attributes["attribute.type"] === "weight"
            );

            const priceAttribute = attributesList.filter(
              (item) =>
                item.type === "attribute" &&
                item.attributes["attribute.type"] === "price"
            );

            if (priceAttribute.length > 0) {
              const itemCardElement = document.querySelector(".click-search");
              itemCardElement.click();
            }
          } else {
            handleAddToCart(e, data?.data?.[0]);
          }
          setIsLoading(false);
        } else {
          setProductList(data);
        }

        // handleAddToCart()
        // order-detail
        // const itemCard = document.getElementsByClassName(".item-card .order-detail");

        // Attach a click event listener to the element
        // itemCard.
        // setProductList(data.data);
        // ////console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="all-dishes">
        <div className="items-dishes">
          <div className="box-dishes">
            <div className="menu-create">
              <Menu gridLayout={handleGridLayout} addToCart={handleAddToCart} />
            </div>
            <LeftSideBar
              categoryChange={handleCategoryChange}
              addToCart={handleAddToCart}
              handleReloadCart={handleReloadCart}
            />

            <div className="outer-box-dishes">
              <div className="row g-2">
                {!isLoading ? (
                  productList.data.map((item, key) => (
                    <div className="col-md-4" key={key}>
                      <BoxFood
                        images={
                          getProductDetailsMultiple(productList, item).media
                            ? getProductDetailsMultiple(productList, item).media
                            : "../../assets/images/category-4.jpg"
                        }
                        title={item.attributes["product.label"]}
                        sub_title={`${
                          getProductDetailsMultiple(productList, item).price
                            ? getProductDetailsMultiple(productList, item).price
                                .attributes["price.value"]
                            : "-"
                        }
                          `}
                        description={`${
                          getProductDetailsMultiple(productList, item).text
                            ? getProductDetailsMultiple(productList, item).text
                                .attributes["text.content"]
                            : ""
                        }
                          `}
                        item={item}
                        handleReloadCart={handleReloadCart}
                        productList={productList}
                      />
                    </div>
                  ))
                ) : (
                  <MyLoader />
                )}
              </div>
            </div>
          </div>
          <RightBar reloadCart={reloadCart} />
        </div>
      </div>
    </>
  );
};

export default Dishes;
