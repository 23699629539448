import React from "react";
import CardPrice from "../Components/CardPrice";
import DisableCard from "../Components/DisableCard";
import TableNumber from "../Components/TableNumber";
import CompleteDishes from "../Components/CompleteDishes";
import MainLayout from "../Layout/MainLayout";

const KitchenOrder = () => {
  return (
    <>
      <MainLayout>
        <div className="kitchen-items-outer">
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                TableCard="Table 05"
                TableCardId="Order No: #9049"
                TableCardIn="Dine-in"
                TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/3)</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <CardPrice
                    ImagesKitchen="../../assets/images/category-1.jpg"
                    Pork="Pork Burger"
                    PorkList="x1"
                    ListSize="- large size"
                    Extra="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <CardPrice
                    ImagesKitchen="../../assets/images/category-1.jpg"
                    Pork="Pork Burger"
                    PorkList="x1"
                    ListSize="- large size"
                    Extra="- extra cheese"
                  />
                </div>

                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                TableCard="Table 14"
                TableCardId="Order No: #9049"
                TableCardIn="Dine-in"
                TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/3)</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <CardPrice
                    ImagesKitchen="../../assets/images/category-1.jpg"
                    Pork="Pork Burger"
                    PorkList="x1"
                    ListSize="- large size"
                    Extra="- extra cheese"
                  />
                </div>

                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                TableCard="Table 17"
                TableCardId="Order No: #9049"
                TableCardIn="Dine-in"
                TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/3)</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                TableCard="Table 18"
                TableCardId="Order No: #9049"
                TableCardIn="Dine-in"
                TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/3)</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                TableCard="Table 02"
                TableCardId="Order No: #9049"
                TableCardIn="Dine-in"
                TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/3)</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <DisableCard
                    DisableHeading="Completed"
                    DisableItem="Pork Burger"
                    DisableNumber="x1"
                    DisableSize=" - large size"
                    DisableCheese="- extra cheese"
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <CompleteDishes
                    DisableHeading1="Completed"
                    DisableItem2="Pork Burger"
                    DisableNumber3="x1"
                    DisableSize4=" - large size"
                    DisableCheese5="- extra cheese"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default KitchenOrder;
