import React from "react";

const PriceCard = (props) => {
  return (
    <>
      <div className="price-card card-style-booking">
        <div className=" order-booking">
          <img
            src={props.CardImages}
            alt=""
            className="img-fluid product-food"
          />
        </div>
        <div className=" order-confirm">
          <h5 className="bill-heading-confirm">{props.heading}</h5>
          <h4 className="sub-heading">{props.price}</h4>
          <div className="list-customer-items">
            <span className="label">{props.Quantity}</span>
            <span className="label">{props.Label}</span>

            <span className="size">{props.Size}</span>
          </div>
          <div className="list-customer-items">
            <span className="label">{props.Ica}</span>

            <span className="size">{props.Less}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceCard;
