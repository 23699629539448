import React, { useState, useEffect } from "react";

const CashCal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <div class="modal-backdrop fade show"></div>
      <div
        className="modal fade show"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-card">
            <div className="modal-header">
              <div className="">
                <h5 className="modal-title" id="exampleModalLabel">
                  Calculate Cash
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashCal;
