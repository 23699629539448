import React from "react";
import { Link } from "react-router-dom";
import LeftSideBar from "../Components/LeftSideBar";
import Menu from "../Components/Menu";
import RightBar from "../Components/RightBar";
import CardFood from "../Components/CardFood";

const GridItemsThree = () => {
  return (
    <>
      <div className="all-dishes">
        <div className="items-dishes">
          <LeftSideBar />

          <div className="box-dishes">
            <Menu />

            <div className="outer-box-dishes">
              <div className="row g-0">
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
                <div className="col-md-4">
                  <CardFood
                    images="../../assets/images/category-4.jpg"
                    title="chicken, egg, mushroom, salad"
                    sub_title="$10.99"
                  />
                </div>
              </div>
            </div>
          </div>
          <RightBar />
        </div>
      </div>
    </>
  );
};

export default GridItemsThree;
