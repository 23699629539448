import React, { useEffect, useState } from "react";
import OrderCustomer from "../Components/OrderCustomer";
import {
  API_GET_CART,
  API_ORDER_UPDATE,
  API_PLACE_ORDER,
} from "../Config/Endpoints";
import {
  getMediaUrlFromString,
  getOrderTypeValue,
  parseAmount,
  ShowToast,
} from "../Services/Helpers";
import MyLoader from "./MyLoader";
import StripeTerminalComponent from "../Services/StripeTerminalComponent";
import { getUserData, logoutUser } from "../Services/Storage";
import CashCal from "./CashCal";
import PrintButton from "./PrintButton";
import CartTotals from "./CartTotals";
let userData = getUserData();

const RightBar = (props) => {
  const { reloadCart } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isPlaceOrderLoading, setIsPlaceOrderLoading] = useState(false);
  const [isCartClearLoading, setIsCartClearLoading] = useState(false);
  const [isCartUpdateLoading, setIsCartUpdateLoading] = useState(false);
  const [orderType, setOrderType] = useState(false);
  const [orderTime, setOrderTime] = useState("asap");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [paymentType, setPaymentType] = useState(false);
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [table, setTable] = useState(false);
  const [tip, setTip] = useState(0);
  const [orderNotes, setOrderNotes] = useState("");
  const [showCashCal, setShowCashCal] = useState(false);
  const [change, setChange] = useState(0);
  const [currentOrder, setCurrentOrder] = useState({
    products: [],
    details: { attributes: [] },
    cart: [],
  });
  const [cart, setCart] = useState({
    products: [],
    details: { attributes: [] },
    cart: [],
  });

  const [addressData, setAddressData] = useState({
    fName: "",
    lName: "",
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "CA",
    postcode: "",
    email: "",
    phone: "",
  });

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
  };

  // Handle time change
  const handleTimeChange = (event) => {
    const time = event.target.value;
    setSelectedTime(time);
  };

  const handleNewOrder = () => {
    setStep(1);
    setOrderType(false);
    setOrderTime("asap");
    setPaymentType(false);
    setShowCardPayment(false);
    setShowAddressForm(false);
    setTable(false);
    setTip(0);
    setOrderNotes("");
    setAddressData({
      fName: "",
      lName: "",
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "CA",
      postcode: "",
      email: "",
      phone: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressData({ ...addressData, [name]: value });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModel = () => {
    setChange(0);
    setIsModalOpen(true);
  };

  const updateCart = (data) => {
    console.log("Cart", data);
    // setCart(cart);
    const cartProducts = data.included.filter((product) => {
      console.log(product);
      return product.type === "basket.product";
    });

    console.log("cartProducts", cartProducts);
    // setCart(data);
    setCart((prevState) => ({
      ...prevState,
      products: cartProducts,
      details: data.data,
      cart: data,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    setStep(1);
    setOrderType(false);
    setPaymentType(false);
    fetch(API_GET_CART + "?id=default", {
      method: "GET",
      crossDomain: true,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('cart from page', data);
        const cartProducts = data.included.filter((product) => {
          console.log(product);
          return product.type === "basket.product";
        });

        console.log("cart from page products", cartProducts);
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
          cart: data,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [reloadCart]);

  const handleClearCart = (e) => {
    setIsCartClearLoading(true);
    fetch(API_GET_CART + "?id=default", {
      method: "DELETE",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("cart deleted", data);
        setCart({
          products: [],
          details: { attributes: [] },
          cart: [],
        });
        setIsCartClearLoading(false);
        setTip(0);
        // handleNewOrder();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveComment = async (e) => {
    await fetch(API_GET_CART + "?id=default", {
      method: "PATCH",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          attributes: {
            order_type: getOrderTypeValue(orderType),
            order_place_type: "POS",
            is_schedule_delivery: orderTime === "asap" ? 0 : 1,
            order_table_no: table,
            scheduled_time: `${selectedDate} ${selectedTime}:00`,
            payment_method: paymentType === "cash" ? "CASH" : "STRIPE",
            ["order.statuspayment"]: paymentType === "cash" ? 6 : -1,
            ["order.comment"]: orderNotes,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("cart deleted", data);
        setCart({
          products: [],
          details: { attributes: [] },
          cart: [],
        });
        setIsCartClearLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTipPayment = async (value) => {
    setTip(value);
    await fetch(API_GET_CART + "?id=default", {
      method: "PATCH",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          attributes: {
            tip_amount: value,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("cart updated", data);
        //

        const cartProducts = data.included.filter((product) => {
          console.log(product);
          return product.type === "basket.product";
        });

        console.log("cart from page products", cartProducts);
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
          cart: data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlaceOrder = async (e) => {
    setIsPlaceOrderLoading(true);

    if (!orderType) {
      ShowToast("error", "Please select order type");
      return false;
    }
    if (!paymentType) {
      ShowToast("error", "Please select payment type");
      return false;
    }
    //check for cart
    console.log("cart", cart);
    const currentCart = cart.cart;

    const cartAddresses = currentCart.included.filter((item) => {
      console.log("item", item);
      return item.type === "basket.address" && item.id === "payment";
    });

    const cartPayments = currentCart.included.filter((item) => {
      console.log("item", item);
      return item.type === "basket.payment" && item.id === "payment";
    });

    if (cartAddresses <= 0) {
      await saveCartAddress();
    }

    if (cartPayments <= 0) {
      await saveCartPayments();
    }
    console.log("cartAddresses", cartAddresses);
    console.log("cartPayments", cartPayments);

    //save comments
    await handleSaveComment();
    //save cart & Place order
    await placeOrder();
    setIsPlaceOrderLoading(false);
    if (paymentType === "cash") {
      closeModal();
    }
    if (paymentType === "card") {
      setShowCardPayment(true);
    }
  };

  const placeOrder = async (e) => {
    //save cart
    await fetch(API_GET_CART, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("save basket", data);
        if (data.errors) {
          data.errors.forEach((element) => {
            ShowToast("error", element.title);
          });
        } else {
          if (paymentType === "cash") {
            ShowToast("success", "Order Placed");
          }
          setStep(3);
          setCurrentOrder(data);

          const cartProducts = data.included.filter((product) => {
            console.log(product);
            return product.type === "basket.product";
          });

          console.log("cart data products", data);
          console.log("cart from page products", cartProducts);
          // setCart(data);
          setCurrentOrder((prevState) => ({
            ...prevState,
            products: cartProducts,
            details: data.data,
            cart: data,
          }));
          handleClearCart(data);
          setChange(0);
          // updateOrder(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveCartAddress = async (e) => {
    await fetch(API_GET_CART + "?id=default&related=address", {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [
          {
            id: "payment",
            attributes: {
              "order.base.address.addressid": "",
              "order.base.address.firstname":
                addressData.fName != "" ? addressData.fName : "Store Order",
              "order.base.address.lastname":
                addressData.lName != "" ? addressData.lName : "Store Order",
              "order.base.address.address1":
                addressData.address1 != ""
                  ? addressData.address1
                  : "Store Address",
              "order.base.address.address2":
                addressData.address2 != ""
                  ? addressData.address2
                  : "Store Address",
              "order.base.address.address3": "",
              "order.base.address.postal":
                addressData.postcode != "" ? addressData.postcode : "123123",
              "order.base.address.city":
                addressData.city != "" ? addressData.city : "City",
              "order.base.address.state":
                addressData.state != "" ? addressData.state : "State",
              "order.base.address.countryid":
                addressData.country != "" ? addressData.country : "US",
              "order.base.address.languageid": "en",
              "order.base.address.telephone":
                addressData.phone != "" ? addressData.phone : "99999999999",
              "order.base.address.email":
                addressData.email != ""
                  ? addressData.email
                  : "test@example.com",
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("save address", data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveCartPayments = async (e) => {
    if (paymentType === "cash") {
      await fetch(
        API_GET_CART + "?id=default&related=service&relatedid=payment",
        {
          method: "POST",
          crossDomain: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: [
              {
                id: "payment",
                attributes: {
                  "service.id": process.env.REACT_APP_PAYMENT_CASH,
                },
              },
            ],
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("save payment", data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await fetch(
        API_GET_CART + "?id=default&related=service&relatedid=payment",
        {
          method: "POST",
          crossDomain: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: [
              {
                id: "payment",
                attributes: {
                  "service.id": process.env.REACT_APP_PAYMENT_STRIPE,
                },
              },
            ],
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("save payment", data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePlaceOrderBtn = async (e) => {
    // alert(orderType);
    if (orderType === "dinein" && (!table || table === "")) {
      ShowToast("error", "Please select table number");
      return false;
    }

    if (!orderType) {
      ShowToast("error", "Please select order type");
      return false;
    }

    if (orderTime !== "asap") {
      if (selectedDate === "" || selectedTime === "") {
        ShowToast("error", "Please delivery date & time");
        return false;
      }
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
      });
      if (selectedTime >= currentTime) {
        console.log(selectedTime);
      } else {
        ShowToast("error", "Selected time is in the past");
        return false;
      }
    }

    if (orderType === "delivery" && addressData.fName === "") {
      ShowToast("error", "Please enter delivery address");
      return false;
    }

    if (!paymentType) {
      ShowToast("error", "Please select payment type");
      return false;
    }

    setIsCartUpdateLoading(true);
    //update cart values
    await fetch(API_GET_CART + "?id=default", {
      method: "PATCH",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          attributes: {
            order_type: getOrderTypeValue(orderType),
            order_place_type: "POS",
            is_schedule_delivery: orderTime === "asap" ? 0 : 1,
            order_table_no: table,
            scheduled_time: `${selectedDate} ${selectedTime}:00`,
            payment_method: paymentType === "cash" ? "CASH" : "STRIPE",
            ["order.statuspayment"]: paymentType === "cash" ? 6 : -1,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("cart updated", data);
        const cartProducts = data.included.filter((product) => {
          console.log(product);
          return product.type === "basket.product";
        });
        // setCart(data);
        setCart((prevState) => ({
          ...prevState,
          products: cartProducts,
          details: data.data,
          cart: data,
        }));
        setIsCartUpdateLoading(false);
        openModel();
      })
      .catch((error) => {
        console.log(error);
        setIsCartUpdateLoading(false);
      });
  };

  const onSucessTerminalPayment = (data) => {
    setStep(3);
    setShowCardPayment(false);
    closeModal();
  };

  const handleCalculateChange = (value, total) => {
    const cashFromCustomer = parseFloat(value);
    const totalAmount = total;
    const calculatedChange = isNaN(cashFromCustomer)
      ? 0
      : (cashFromCustomer - totalAmount).toFixed(2);
    setChange(calculatedChange);
  };

  const handleEventUpdate = (event, value, e) => {
    if (event === "ordertype") {
      setOrderType(value);
    }
  };

  const handleBack = (e) => {
    setStep(1, e);
    setOrderType(false);
    setPaymentType(false);
    setOrderTime("asap");
    setSelectedDate("");
    setSelectedTime("");
    handleTipPayment(0);
  };

  return (
    <>
      <div className="right-check-bill">
        <div className="order-table">
          <div className="food-order-items">
            {step === 1 ? (
              <div className="tab-content" id="nav-tabContent">
                <div className="table_number">
                  <h3 className="table__number__order">New Order</h3>
                </div>
                {/* {isLoading ? (
                  // <MyLoader />
                  <></>
                ) : ( */}
                <div className="tab-content-list">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {cart.products.map((item, key) => (
                      <OrderCustomer
                        key={key}
                        orderDishesImage={getMediaUrlFromString(
                          item.attributes["order.product.mediaurl"]
                        )}
                        heading={item.attributes["order.product.name"]}
                        qty={item.attributes["order.product.quantity"]}
                        // Label="size"
                        // Size="large"
                        price={`${item.attributes["order.product.price"]}`}
                        rebate={`${item.attributes["order.product.rebate"]}`}
                        // Ica="ice"
                        // Less="less"
                        item={item}
                        updateCart={updateCart}
                        productList={cart.cart}
                      />
                    ))}
                  </div>
                </div>
                {/* )} */}
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  lorem300
                </div>
              </div>
            ) : null}
            {step === 2 ? (
              <div className="tab-content " id="nav-tabContent">
                <h6 className="place-title">Place Order</h6>
                {/* <hr></hr> */}
                <div className="tab-content-list-2">
                  <p className="items-of-order-type">Order Type</p>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <button
                      type="button"
                      className={`verification ${
                        orderType === "delivery" ? "active" : null
                      }`}
                      onClick={(e) => setOrderType("delivery", e)}
                    >
                      {/* <i className="fa fa-archive" aria-hidden="true"></i> */}
                      {/* <i className="fa-solid fa-people-carry-box"></i> */}
                      <p className="delivery-dine">Delivery</p>
                    </button>

                    <button
                      type="button"
                      className={`verification ${
                        orderType === "takeaway" ? "active" : null
                      }`}
                      onClick={(e) => setOrderType("takeaway", e)}
                    >
                      {/* <i className="fa fa-cube" aria-hidden="true"></i> */}
                      <p className="delivery-dine">In-Store</p>
                    </button>
                    <button
                      type="button"
                      className={`verification ${
                        orderType === "dinein" ? "active" : null
                      }`}
                      onClick={(e) => setOrderType("dinein", e)}
                    >
                      {/* <i className=fa fa-cutlery" aria-hidden="true"></i> */}
                      {/* <i className=fa fa-cutlery" aria-hidden="true"></i> */}

                      <p className="delivery-dine">Dine-In</p>
                    </button>
                  </div>
                  <div className="deliver-time">
                    {orderType === "delivery" || orderType === "takeaway" ? (
                      <div className="radioBtn">
                        <input
                          className="radio-input"
                          type="radio"
                          name="gridRadiosNew"
                          id="gridRadios1"
                          value="asap"
                          onClick={() => setOrderTime("asap")}
                          checked={orderTime === "asap" ? "checked" : false}
                        />
                        <label
                          className="radio__label label-asap"
                          htmlFor="gridRadios1"
                        >
                          ASAP
                        </label>
                        <input
                          className="radio-input"
                          type="radio"
                          name="gridRadiosNew"
                          id="gridRadios2"
                          value="schedule"
                          onClick={() => setOrderTime("schedule")}
                          checked={orderTime === "schedule" ? "checked" : false}
                        />
                        <label
                          className="radio__label label-asap"
                          htmlFor="gridRadios2"
                        >
                          Schedule
                        </label>
                      </div>
                    ) : null}

                    <div className="deliver-text"></div>
                    {orderTime == "schedule" && orderType != "dinein" ? (
                      <div className="schedule">
                        <div className="select-schedule">
                          <label htmlhtmlFor="name">Date</label>
                          <input
                            type="date"
                            className="input-list"
                            placeholder="Date"
                            name="date"
                            min={new Date().toISOString().split("T")[0]}
                            onChange={handleDateChange}
                          />
                        </div>
                        <div className="select-schedule">
                          <label htmlhtmlFor="name">Time</label>
                          <input
                            type="time"
                            className="form-control input-list"
                            onChange={handleTimeChange}
                          />
                        </div>
                      </div>
                    ) : null}

                    {orderType === "dinein" ? (
                      <div className="dine-in">
                        <h3>Table No :</h3>
                        <select
                          className="form-select"
                          onChange={(e) => setTable(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Table No.1">Table No.1</option>
                          <option value="Table No.2">Table No.2</option>
                          <option value="Table No.3">Table No.3</option>
                          <option value="Table No.4">Table No.4</option>
                          <option value="Table No.5">Table No.5</option>
                          <option value="Table No.6">Table No.6</option>
                          <option value="Table No.7">Table No.7</option>
                          <option value="Table No.8">Table No.8</option>
                          <option value="Table No.9">Table No.9</option>
                        </select>
                      </div>
                    ) : null}
                    <div className="dine-in">
                      <h3>Add Tip</h3>
                      {/* <select
                        className="form-select"
                        onChange={(e) => handleTipPayment(e)}
                        value={tip}
                      >
                        <option value="0">$0</option>
                        <option value="10">$10</option>
                        <option value="15">$15</option>
                        <option value="20">$20</option>
                        <option value="25">$25</option>
                      </select> */}
                    </div>
                    <div className="radioBtn radio-changes">
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios-2"
                        value="option1"
                        onChange={(e) => handleTipPayment(1)}
                        checked={tip === 1}
                      />
                      <label
                        className="radio__label label-title"
                        htmlFor="gridRadios-2"
                      >
                        <h3 className="button-dollar-tip">$1.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios11"
                        value="option5"
                        onChange={(e) => handleTipPayment(5)}
                        checked={tip === 5}
                      />
                      <label
                        className="radio__label label-title"
                        htmlFor="gridRadios11"
                      >
                        <h3 className="button-dollar-tip">$5.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios8"
                        value="option10"
                        onChange={(e) => handleTipPayment(10)}
                        checked={tip === 10}
                      />
                      <label className="radio__label" htmlFor="gridRadios8">
                        <h3 className="button-dollar-tip">$10.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios9"
                        value="option15"
                        onChange={(e) => handleTipPayment(15)}
                        checked={tip === 15}
                      />
                      <label
                        className="radio__label label-title"
                        htmlFor="gridRadios9"
                      >
                        <h3 className="button-dollar-tip">$15.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadiosTwo"
                        value="option20"
                        onChange={(e) => handleTipPayment(20)}
                        checked={tip === 20}
                      />
                      <label
                        className="radio__label label-title"
                        htmlFor="gridRadiosTwo"
                      >
                        <h3 className="button-dollar-tip">$20.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios12"
                        value="option30"
                        onChange={(e) => handleTipPayment(30)}
                        checked={tip === 30}
                      />
                      <label className="radio__label" htmlFor="gridRadios12">
                        <h3 className="button-dollar-tip">$30.00</h3>
                      </label>
                    </div>
                    {/* <div className="radioBtn radio-changes1-2">
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios9"
                        value="option15"
                        onChange={(e) => handleTipPayment(15)}
                        checked={tip === 15}
                      />
                      <label className="radio__label label-title" htmlFor="gridRadios9">
                        <h3 className="button-dollar-tip">$15.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadiosTwo"
                        value="option20"
                        onChange={(e) => handleTipPayment(20)}
                        checked={tip === 20}
                      />
                      <label
                        className="radio__label label-title"
                        htmlFor="gridRadiosTwo"
                      >
                        <h3 className="button-dollar-tip">$20.00</h3>
                      </label>
                      <input
                        className="radio-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios12"
                        value="option30"
                        onChange={(e) => handleTipPayment(30)}
                        checked={tip === 30}
                      />
                      <label className="radio__label" htmlFor="gridRadios12">
                        <h3 className="button-dollar-tip">$30.00</h3>
                      </label>
                    </div> */}

                    {orderType === "delivery" ? (
                      <>
                        <div className="add-user-address">
                          {/* <a href="#" onClick={() => setShowAddressForm(true)}> */}
                          {addressData.fName === "" ? (
                            <a
                              href="#"
                              onClick={() => setShowAddressForm(true)}
                            >
                              <i className="fa fa-plus pe-1 mt-2"></i>
                              Add Address
                            </a>
                          ) : null}
                        </div>

                        <div className="text-area">
                          <label
                            htmlFor="Textarea1"
                            className="form-label"
                            // data-bs-target="#exampleModal1"
                            // data-bs-toggle="modal"
                          >
                            Address
                          </label>
                        </div>
                        {addressData.fName !== "" ? (
                          <div className="address-user">
                            <div>
                              <p>
                                First Name : <span>{addressData.fName}</span>
                              </p>
                              <p>
                                {" "}
                                Email Address : <span>{addressData.email}</span>
                              </p>
                              <p>
                                Address :{" "}
                                <span>
                                  {addressData.address1}, {addressData.address2}
                                  <br />
                                  {addressData.city}, {addressData.state},{" "}
                                  {addressData.country}{" "}
                                </span>
                              </p>
                              <p>
                                Mobile : <span>{addressData.phone}</span>
                              </p>
                            </div>
                            {addressData.fName != "" ? (
                              <a
                                href="#"
                                onClick={() => setShowAddressForm(true)}
                              >
                                Edit
                              </a>
                            ) : null}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  {/* Button trigger modal */}
                  {/* Modal */}
                  {showAddressForm ? (
                    <>
                      <div className="modal-backdrop fade show"></div>
                      <div
                        className="modal fade show"
                        id="exampleModal1"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        style={{ display: "block" }}
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Enter Address
                              </h5>
                            </div>
                            <div className="modal-body modal-form">
                              {/* <form action="" onSubmit={handleSubmit}> */}
                              <form action="">
                                <div className="row">
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="fName"
                                      className="form-label"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="fName"
                                      name="fName"
                                      placeholder="First Name"
                                      value={addressData.fName}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="lName"
                                      className="form-label"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="lName"
                                      name="lName"
                                      placeholder="Last Name"
                                      value={addressData.lName}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <label
                                      htmlhtmlFor="address"
                                      className="form-label"
                                    >
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address"
                                      name="address"
                                      placeholder="Enter address"
                                      value={addressData.address}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="address1"
                                      className="form-label"
                                    >
                                      Address Line 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address1"
                                      name="address1"
                                      value={addressData.address1}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="address2"
                                      className="form-label"
                                    >
                                      Address Line 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address2"
                                      name="address2"
                                      value={addressData.address2}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <label
                                      htmlhtmlFor="city"
                                      className="form-label"
                                    >
                                      Town / City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="city"
                                      name="city"
                                      value={addressData.city}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="state"
                                      className="form-label"
                                    >
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="state"
                                      name="state"
                                      value={addressData.state}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="country"
                                      className="form-label"
                                    >
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="country"
                                      name="country"
                                      value={addressData.country}
                                      onChange={handleInputChange}
                                      readOnly
                                    />
                                  </div>{" "}
                                  <div className="col-12">
                                    <label
                                      htmlhtmlFor="postcode"
                                      className="form-label"
                                    >
                                      Postcode / ZIP
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="postcode"
                                      name="postcode"
                                      value={addressData.postcode}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="email"
                                      className="form-label"
                                    >
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      placeholder="Email address"
                                      value={addressData.email}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label
                                      htmlhtmlFor="phone"
                                      className="form-label"
                                    >
                                      Phone
                                    </label>
                                    <input
                                      type="mobile"
                                      className="form-control"
                                      id="phone"
                                      name="phone"
                                      placeholder="Phone"
                                      value={addressData.phone}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                                {/* <button type="submit">Submit</button> */}
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="theme-black"
                                data-bs-dismiss="modal"
                                onClick={() => setShowAddressForm(false)}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="theme-black"
                                id="form-submit"
                                onClick={() => setShowAddressForm(false)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* <hr></hr> */}
                  <p className="items-of-order-type">Payment Mode</p>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic outlined example"
                  >
                    <button
                      type="button"
                      className={`verification ${
                        paymentType === "cash" ? "active" : null
                      }`}
                      onClick={(e) => setPaymentType("cash", e)}
                    >
                      {/* <i className="fa fa-usd" aria-hidden="true"></i> */}
                      <p className="card-payment">Cash</p>
                    </button>
                    <button
                      type="button"
                      className={`verification ${
                        paymentType === "card" ? "active" : null
                      }`}
                      onClick={(e) => setPaymentType("card", e)}
                    >
                      {/* <i className="fa fa-credit-card" aria-hidden="true"></i> */}
                      <p className="card-payment">Card</p>
                      {/* <small>Coming soon</small> */}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {step === 3 ? (
              <div className="tab-content" id="nav-tabContent">
                {" "}
                <div className="order-number-table-user">
                  <h6> Order Details</h6>
                  <div className="table_number table_number-list">
                    <h3 className="table-order__number_list">
                      Order:
                      <span>
                        #{currentOrder.details.attributes["order.id"]}
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="tab-content-list">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Items</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOrder.products.map((item, key) => (
                        <tr key={key}>
                          <th>{key + 1}</th>
                          <td>{item.attributes["order.product.name"]}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.attributes["order.product.quantity"]}
                          </td>
                          <td>
                            {item.attributes["order.product.rebate"] < 0 && (
                              <>
                                <strike className="text-black-50">
                                  $
                                  {parseAmount(
                                    parseFloat(
                                      item.attributes["order.product.price"]
                                    ) -
                                      parseFloat(
                                        item.attributes["order.product.rebate"]
                                      )
                                  )}
                                </strike>
                              </>
                            )}
                            <br />${item.attributes["order.product.price"]}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            $
                            {parseFloat(
                              item.attributes["order.product.quantity"] *
                                item.attributes["order.product.price"]
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <>
            {" "}
            {step !== 3 ? <CartTotals cart={cart} tip={tip} /> : null}
            {step === 3 ? <CartTotals cart={currentOrder} tip={tip} /> : null}
          </>

          {step !== 3 ? (
            <div
              className="btn-group btn-group-items"
              role="group"
              aria-label="Basic outlined example"
            >
              {step === 2 ? (
                <>
                  {" "}
                  <button
                    type="button"
                    className="verification continue-btn-order"
                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop"
                    // onClick={(e) => handlePlaceOrder(e)}
                    onClick={(e) => handlePlaceOrderBtn(e)}
                  >
                    <p>
                      Place Order
                      {isCartUpdateLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null}
                    </p>
                  </button>
                  <button
                    type="button"
                    className="verification close-btn"
                    onClick={handleBack}
                  >
                    <i
                      className="fa fa-chevron-left back-icon"
                      aria-hidden="true"
                    ></i>
                    <p>Back</p>
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="verification continue-btn-order"
                    onClick={(e) =>
                      cart.products.length > 0
                        ? setStep(2, e)
                        : ShowToast("error", "No Products")
                    }
                  >
                    {/* <i className="fa fa-paper-plane-o"></i> */}
                    <p>Create Order</p>
                  </button>

                  <button
                    type="button"
                    className="verification close-btn"
                    onClick={(e) => handleClearCart(e)}
                  >
                    {isCartClearLoading ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <img
                        src="../../assets/images/close-btn.png"
                        alt=""
                        className="close-button"
                      />
                      // <i className="fa fa-window-close-o close-button" aria-hidden="true"></i>
                    )}
                    {/* <p>Clear</p> */}
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <div
                className="btn-group btn-group-items"
                role="group"
                aria-label="Basic outlined example"
              >
                <PrintButton orderDetails={currentOrder} />

                <button
                  type="button"
                  className="verification close-btn"
                  onClick={() => handleNewOrder()}
                >
                  <i className="fa fa-cutlery back-icon" aria-hidden="true"></i>
                  <p className="new-order-create">New Order</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content customer-order-place ">
                {showCardPayment ? (
                  <>
                    <StripeTerminalComponent
                      onPayment={onSucessTerminalPayment}
                      orderId={currentOrder.details.attributes["order.id"]}
                      amount={(
                        parseFloat(
                          currentOrder.details.attributes["order.taxvalue"]
                        ) +
                        parseFloat(
                          currentOrder.details.attributes["order.price"]
                        )
                      )
                        // parseFloat(currentOrder)
                        .toFixed(2)}
                    />
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="theme-black"
                        data-bs-dismiss="modal"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="review-order">
                        <h3 className="order-details">ORDER DETAILS</h3>
                        <h4 className="dine-in-list uppercase">{orderType}</h4>
                        {orderType === "dinein" ? (
                          <h4 className="table-no">{table}</h4>
                        ) : null}
                        <h4 className="table-no">
                          Payment Mode :{" "}
                          <span className="uppercase">{paymentType}</span>
                        </h4>
                        <div className="customer-order">
                          <div className="order-table-list-items">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Items</th>
                                  <th style={{ textAlign: "center" }}>
                                    Quantity
                                  </th>
                                  <th style={{ textAlign: "right" }}>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cart.products.map((item, key) => (
                                  <tr key={key}>
                                    <th>{key + 1}</th>
                                    <td>
                                      {item.attributes["order.product.name"]}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {
                                        item.attributes[
                                          "order.product.quantity"
                                        ]
                                      }
                                    </td>
                                    {/* <td>
                                  ${item.attributes["order.product.price"]}
                                </td> */}
                                    <td style={{ textAlign: "end" }}>
                                      $
                                      {parseFloat(
                                        item.attributes[
                                          "order.product.quantity"
                                        ] *
                                          item.attributes["order.product.price"]
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="customer-pye-bill">
                            <p className="all-total">total</p>
                            <h3 className="payment">
                              {" "}
                              $
                              {parseAmount(
                                cart.details.attributes["order.final_amount"]
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comment-section">
                        <h3 className="nots">Notes</h3>
                        <textarea
                          className="form-control"
                          rows="6"
                          placeholder="Notes"
                          onChange={(e) => setOrderNotes(e.target.value)}
                        ></textarea>
                        {paymentType === "cash" ? (
                          <div>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control mtop0"
                                placeholder="Cash from Customer"
                                onChange={(e) =>
                                  handleCalculateChange(
                                    e.target.value,
                                    (
                                      parseFloat(
                                        cart.details.attributes[
                                          "order.taxvalue"
                                        ]
                                      ) +
                                      parseFloat(
                                        cart.details.attributes["order.price"]
                                      )
                                    ).toFixed(2)
                                  )
                                }
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  Return : ${change}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="please-btn ">
                          <button
                            type="button"
                            className="theme-black"
                            onClick={closeModal}
                          >
                            Close
                          </button>
                          {paymentType === "cash" ? (
                            <button
                              type="button"
                              className="theme-black"
                              onClick={(e) => {
                                handlePlaceOrder(e);
                                // setShowCashCal(true);
                              }}
                              // data-bs-toggle="modal"
                              // data-bs-target="#staticBackdrop1"
                              disabled={isPlaceOrderLoading}
                            >
                              {isPlaceOrderLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Place Order
                            </button>
                          ) : null}
                          {paymentType === "card" ? (
                            <button
                              type="button"
                              className="theme-black"
                              onClick={(e) => handlePlaceOrder(e)}
                              // data-bs-toggle="modal"
                              // data-bs-target="#staticBackdrop1"
                              disabled={isPlaceOrderLoading}
                            >
                              {isPlaceOrderLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Place Order & Payment
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {showCashCal ? <CashCal /> : null}
    </>
  );
};

export default RightBar;
