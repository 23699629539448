import React from "react";
import { Link } from "react-router-dom";
import Clock from "react-live-clock";

const KitchenMenuItems = ({ onFliterClick }) => {
  const handleButtonClick = () => {
    console.log("in kitchen");
    // Prepare data to send back to the parent component
    const dataToSend = "Data from KitchenMenuItems";

    // Invoke the function received from props
    onFliterClick(dataToSend);
  };
  return (
    <>
      <div className="kitchen-menu-list">
        <Link to={"/"}>
          <img
            src="../../assets/images/logo.svg"
            alt="logo.svg"
            className="img-fluid kitchen-logo"
          />
        </Link>

        <div>
          <ul className="kitchen-menu">
            <li>
              <Link to="/">Customer</Link>
            </li>
            {/* <li>
            <Link to="/StockProduct">Menu Stock</Link>
          </li> */}
            <li>
              <Link to="/HistoryOrder" className="active">
                Order History
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <a onClick={() => handleButtonClick()}>
            <h4 className="popup">Filter</h4>
          </a>
        </div>
      </div>
      {/* <div className="kitchen-date-timing">
          <div className="search-input-time"> */}
      {/* <input
              type="search"
              className="form-control"
              placeholder="search"
            />
            <i className="fa fa-search"></i> */}
      {/* </div>

          <h2 className="kitchen-heading">
            {" "}
            <Clock
              format={"hh:mm:ss A"}
              ticking={true}
              timezone={"US/Pacific"}
            />
          </h2> */}
      {/* </div> */}
      {/* <div className="kitchen-link">
          <Link to="/KitchenOrder">
            <img src="../../assets/images/circle.png" alt="" />
          </Link>
          <Link to="/AvailableTable">
            <img src="../../assets/images/calendar.png" alt="" />
          </Link>
          <Link to="/StockProduct">
            <img src="../../assets/images/round.png" alt="" />
          </Link>
        </div> */}
    </>
  );
};

export default KitchenMenuItems;
