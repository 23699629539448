import React from "react";
import ContentLoader from "react-content-loader";

const MyListLoader = (props) => (
  <ContentLoader
    speed={2}
    width={500}
    height={410}
    viewBox="0 0 500 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="14" cy="24" r="12" />
    <rect x="38" y="17" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="71" r="12" />
    <rect x="38" y="64" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="118" r="12" />
    <rect x="38" y="111" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="165" r="12" />
    <rect x="38" y="157" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="207" r="12" />
    <rect x="37" y="200" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="254" r="12" />
    <rect x="38" y="247" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="301" r="12" />
    <rect x="38" y="294" rx="5" ry="5" width="343" height="16" />
    <circle cx="14" cy="348" r="12" />
    <rect x="38" y="340" rx="5" ry="5" width="343" height="16" />
    <circle cx="15" cy="391" r="12" />
    <rect x="38" y="384" rx="5" ry="5" width="343" height="16" />
    <circle cx="15" cy="438" r="12" />
    <rect x="39" y="431" rx="5" ry="5" width="343" height="16" />
    <circle cx="15" cy="485" r="12" />
    <rect x="39" y="478" rx="5" ry="5" width="343" height="16" />
    <circle cx="15" cy="532" r="12" />
    <rect x="39" y="524" rx="5" ry="5" width="343" height="16" />
  </ContentLoader>
);

export default MyListLoader;
