import React from 'react'

const CardFood = (props) => {
    return (
        <>


            <div className="card">
                <img src={props.images} className="img-fluid" alt="" />
                <div className="card-body">
                    {/* <h5 className="card-title">{props.heading}</h5> */}
                    <p className="card-text">{props.title}
                    </p>
                    <h3 className="sub_title">
                        {props.sub_title}
                    </h3>
                </div>

            </div>
        </>
    )
};

export default CardFood;
