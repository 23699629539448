// import { useState } from "react";
import { React, useEffect, useState } from "react";
import AddToCartModal from "./AddToCartModal";

const BoxFood = (props) => {
  const { item, handleReloadCart, productList, cart, openAddTocart } = props;
  const [count, setCount] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductAddLoading, setIsProductAddLoading] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsProductAddLoading(false);
  };

  const openModel = () => {
    console.log("true");
    setIsModalOpen(true);
    // //render AddToCartModal from here without state
  };

  return (
    <>
      <div
        className={`item-card click-search ${
          item?.attributes?.["product.instock"] === 0 ? "out-of-stock" : ""
        }`}
        onClick={item?.attributes?.["product.instock"] !== 0 ? openModel : null}
      >
        <div className="card-images">
          <img src={props.images} className="product-items-image" alt="" />
        </div>
        <div className="card-body">
          {/* <h5 className="card-title">{props.heading}</h5> */}
          <p className="card-text">{props.title}</p>
          <h3 className="sub_title">${props.sub_title}</h3>
        </div>
      </div>
      {isModalOpen ? (
        <AddToCartModal
          title={props.title}
          price={props.sub_title}
          description={props.description}
          item={item}
          productList={productList}
          onClose={closeModal}
          handleReloadCart={handleReloadCart}
        />
      ) : null}
    </>
  );
};

export default BoxFood;
