import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Styles/theme.scss";
import { AuthInterceptor } from "./Services/AuthInterceptor";
import { API_ORDER_CONNECTION_TOKEN } from "./Config/Endpoints";
const root = ReactDOM.createRoot(document.getElementById("root"));

// AuthInterceptor();

var terminal = StripeTerminal.create({
  onFetchConnectionToken: fetchConnectionToken,
  onUnexpectedReaderDisconnect: unexpectedDisconnect,
});

function unexpectedDisconnect() {
  // In this function, your app should notify the user that the reader disconnected.
  // You can also include a way to attempt to reconnect to a reader.
  console.log("Disconnected from reader");
}

async function fetchConnectionToken() {
  const response = await fetch(API_ORDER_CONNECTION_TOKEN, { method: "POST" });
  const data = await response.json();
  console.log("fetchConnectionToken", data.secret);
  return data.secret;
}

root.render(
  <React.StrictMode>
    <App terminal={terminal} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
