import React from "react";
import MainLayout from "../Layout/MainLayout";
import RightBar from "../Components/RightBar";

const Counter = () => {
  return (
    <>
      <MainLayout>
        <div className="counter-checkout">
          <div className="kitchen-items-outer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="bill-customer-box">
                    <div className="counter-outer-table">
                      <h3>Table</h3>
                      <h1>01</h1>
                      <p>8 order</p>
                    </div>
                    <div className="user-table">
                      <div className="row w-100 g-0">
                        <div className="col-6">
                          <div className="user">
                            <img
                              src="../../assets/images/user.png"
                              alt="user.png"
                              className="img-fluid"
                            />
                            <p>4/4</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="time-counter">
                            <img
                              src="../../assets/images/time.png"
                              alt=""
                              className="img-fluid"
                            />
                            <p>35:20</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-table">
                      <div className="row w-100 g-0">
                        <div className="col-6">
                          <div className="user">
                            <img
                              src="../../assets/images/book.png"
                              alt="user.png"
                              className="img-fluid"
                            />
                            <p>$52.94</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="time-counter">
                            <img
                              src="../../assets/images/time.png"
                              alt=""
                              className="img-fluid "
                            />
                            <p>Unpaid</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <RightBar /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default Counter;
