import "./App.css";
import { React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dishes from "./Pages/Dishes";
import Stripe from "./Pages/Stripe";
import GridItemsFive from "./Pages/GridItemsFive";
import GridItemsThree from "./Pages/GridItemsThree";
import Vegetarian from "./Pages/Vegetarian";
import NonVeg from "./Pages/NonVeg";
import Italian from "./Pages/Italian";
import Shakes from "./Pages/Shakes";
import Asian from "./Pages/Asian";
import Chinese from "./Pages/Chinese";
import KitchenOrder from "./Pages/KitchenOrder";
import AvailableTable from "./Pages/AvailableTable";
import StockProduct from "./Pages/StockProduct";
import Counter from "./Pages/Counter";
import HistoryOrder from "./Pages/HistoryOrder";
import Login from "./Pages/Login";
import Bill from "./Pages/Bill";

function App(props) {
  let { terminal } = props;
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar enableHtml={true} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dishes />} />
          <Route path="/stripe" element={<Stripe terminal={terminal} />} />
          <Route path="/GridItemsFive" element={<GridItemsFive />} />
          <Route path="/GridItemsThree" element={<GridItemsThree />} />
          <Route path="/Vegetarian" element={<Vegetarian />} />
          <Route path="/NonVeg" element={<NonVeg />} />
          <Route path="/Italian" element={<Italian />} />
          <Route path="/Shakes" element={<Shakes />} />
          <Route path="/Asian" element={<Asian />} />
          <Route path="/Chinese" element={<Chinese />} />
          <Route path="/KitchenOrder" element={<KitchenOrder />} />
          <Route path="/AvailableTable" element={<AvailableTable />} />
          <Route path="/StockProduct" element={<StockProduct />} />
          <Route path="/Counter" element={<Counter />} />
          <Route path="/HistoryOrder" element={<HistoryOrder />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/Bill" exact element={<Bill />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
