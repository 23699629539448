import React, { useState, useEffect } from "react";
import { parseAmount } from "../Services/Helpers";

const CartTotals = (props) => {
  const { cart, tip } = props;

  useEffect(() => {
    console.log("cart total", cart);
  }, []);
  //   const cartDetails = cart.cart;
  return (
    <>
      {" "}
      <div className="subtotal">
        <div className="taxes">
          <p className="texes__title">Amount</p>
          <h3 className="texes__heading">
            $
            {parseAmount(
              cart.details.attributes["order.price"] -
                cart.details.attributes["order.rebate"]
            )}
          </h3>
        </div>
        <div className="pye-bile">
          <p className="texes__title">Discount</p>
          <h3 className="texes__heading">
            ${parseAmount(-cart.details.attributes["order.rebate"])}
          </h3>
        </div>
        <div className="pye-bile">
          <p className="texes__title">Taxes</p>
          <h3 className="texes__heading">
            ${parseAmount(cart.details.attributes["order.taxvalue"])}
          </h3>
        </div>
        <div className="pye-bile">
          <p className="texes__title">Tip</p>
          <h3 className="texes__heading">
            ${parseAmount(cart.details.attributes["tip_amount"])}
          </h3>
        </div>
        <div className="customer-pye-bill">
          <p className="all-total">Total</p>
          <h3 className="all-total">
            ${parseAmount(cart.details.attributes["order.final_amount"])}
          </h3>
        </div>
      </div>
    </>
  );
};

export default CartTotals;
