import { toast } from "react-toastify";

export const ShowToast = (type, title) => {
  const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: "dark",
    html: true,
  };

  if (type === "success") {
    toast.success(title, options);
  }

  if (type === "info") {
    toast.info(title, options);
  }

  if (type === "warning") {
    toast.warning(title, options);
  }

  if (type === "error") {
    toast.error(title, options);
  }
};

export const Temp = () => {};

const findObject = (obj, find) => {
  let objs = obj.filter((x) => x.type.includes(find));
  return objs;
};

const findMediaObject = (obj, find) => {
  let objs = obj.filter((x) => x.type.includes(find));
  objs = objs.filter((x) => x.attributes["media.domain"].includes("product"));
  //console.log('objs', objs);
  return objs;
};

export const getProductText = (product) => {
  let productInfo = {};
  console.log("helper", product);
  let text = findObject(product.included, "text");
  text.forEach((element) => {
    if (element.attributes["text.type"] == "long") {
      productInfo.longDescription = element.attributes["text.content"];
    }
    if (element.attributes["text.type"] == "short") {
      productInfo.shortDescription = element.attributes["text.content"];
    }
    if (element.attributes["text.type"] == "meta-description") {
      productInfo.metaDescription = element.attributes["text.content"];
    }
    if (element.attributes["text.type"] == "meta-keyword") {
      productInfo.metaKeyword = element.attributes["text.content"];
    }
  });

  productInfo.id = product.data.attributes["product.id"];
  productInfo.name = product.data.attributes["product.label"];
  productInfo.code = product.data.attributes["product.code"];
  productInfo.rating = product.data.attributes["product.rating"];
  productInfo.ratings = product.data.attributes["product.ratings"];
  productInfo.url = product.data.attributes["product.url"];
  productInfo.status = product.data.attributes["product.status"];
  productInfo.catalog = findObject(product.included, "catalog");
  productInfo.stock =
    typeof findObject(product.included, "stock") !== "undefined"
      ? findObject(product.included, "stock")[0]
      : null;
  productInfo.price =
    typeof findObject(product.included, "price") !== "undefined"
      ? findObject(product.included, "price")[0]
      : null;
  productInfo.media = findMediaObject(product.included, "media");
  //console.log('productInfo', productInfo);
  return productInfo;
};

export const getMediaUrl = (media, featured = true) => {
  // console.log("media", media[0]);
  if (media != null) {
    if (featured == true) {
      if (typeof media[0] === "undefined") {
        ("../../assets/images/category-4.jpg");
      } else {
        return (
          process.env.REACT_APP_API_ENDPOINT +
          "/aimeos/" +
          media[0].attributes["media.url"]
        );
      }
    } else {
      let allMedia = [];
      media.forEach((element) => {
        //console.log('element', process.env.REACT_APP_API_ENDPOINT + '/aimeos/' + element.attributes['media.url']);
        allMedia.push(
          process.env.REACT_APP_API_ENDPOINT +
            "/aimeos/" +
            element.attributes["media.url"]
        );
      });
      return allMedia;
    }
  }
  return null;
};

export const getMediaUrlFromString = (string) => {
  return process.env.REACT_APP_API_ENDPOINT + "/aimeos/" + string;
};

export const getProductDetails = (product, type) => {
  // //console.log('Form Product==', product);
  // //console.log('FInd===', type);
  // //console.log('=================');
  let details = [];
  if (product != null) {
    const dataIds = product.data.relationships[type];
    // //console.log(product.data.relationships[type]);
    if (dataIds != null) {
      dataIds.data.forEach((element) => {
        //   //console.log('element', element);
        const temp = product.included.filter(function (product) {
          // //console.log('product', product);
          return product.id == element.id && product.type == type;
        });
        details.push(temp);
      });
      // //console.log(details);
    }
  }
  return details;
};

export const getProductDetailsMultiple = (productList, item) => {
  // console.log("Product", productList);
  // console.log("Item", item);

  let productInfo = {};
  const priceId = item.relationships.price
    ? item.relationships.price.data[0].id
    : null;
  const mediaId = item.relationships.media
    ? item.relationships.media.data[0].id
    : null;
  const textId = item.relationships.text
    ? item.relationships.text.data[0].id
    : null;
  // console.log("textId", textId);
  let price = productList.included.filter(
    (x) => x.id == priceId && x.type === "price"
  );
  let media = productList.included.filter((x) => {
    return x.id == mediaId && x.type === "media";
  });
  let text = productList.included.filter((x) => {
    return x.id == textId && x.type === "text";
  });
  // console.log("text", text);
  // console.log("media", media[0]);
  productInfo.price = price[0];
  productInfo.media = getMediaUrl([media[0]], true);
  productInfo.text = text[0];
  // //console.log('mediaIds', mediaId);
  // console.log("productInfo", productInfo);
  return productInfo;
};

export const getCategoryDetailsMultiple = (categoryList, item) => {
  // console.log("category", categoryList);
  // console.log("Item", item);

  let categoryInfo = {};
  categoryInfo.media = "";
  // const priceId = item.relationships.price ? item.relationships.price.data[0].id : null;
  const mediaId =
    item.relationships && item.relationships.media
      ? item.relationships.media.data[0].id
      : null;
  // console.log("mediaid", mediaId);
  // let price = productList.included.filter((x) => x.id.includes(priceId));
  let media = categoryList.filter((x) => x.id.includes(mediaId));
  // // productInfo.price = price[0];
  categoryInfo.media = media.length > 0 ? getMediaUrl([media[0]], true) : null;
  //console.log('mediaIds', media);
  //console.log('categoryInfo', categoryInfo);
  return categoryInfo;
};

export const getItemDetails = (item, type, find, key) => {
  // //console.log('item', item);
  // //console.log('find', find);
  let details = "";
  if (item != null) {
    item.forEach((element) => {
      // //console.log('element', element[0]);
      // //console.log(element[0].attributes[type + '.type'], find);
      if (element[0].attributes[type + ".type"] === find) {
        // //console.log('found', element[0].attributes[key]);
        details = element[0].attributes[key];
      }
      //   const temp = element[0].attributes.filter(function (product) {
      //     //console.log('product', product);
      //     // return product.id == element.id && product.type == type;
      //   });
      //   details.push(temp);
      //   //console.log('temp', temp);
    });
  }
  return details;
};

export const getProductUrl = (item) => {
  return `/product/${item.id}/${item.attributes["product.url"]}`;
};

export const getOrderTypeValue = (data) => {
  if (data === "dinein") {
    return "DINE_IN";
  }
  if (data === "takeaway") {
    return "TAKE_AWAY";
  }
  if (data === "delivery") {
    return "DELIVERY";
  }
};

export const parseAmount = (amt) => {
  return amt ? parseFloat(amt).toFixed(2) : 0;
};

export const getOrderPaymentStatus = (status) => {
  if (status === -1) {
    return "Unfinished";
  }
  if (status === 0) {
    return "Deleted";
  }
  if (status === 1) {
    return "Canceled";
  }
  if (status === 2) {
    return "Refund";
  }
  if (status === 3) {
    return "Refund";
  }
  if (status === 4) {
    return "Pending";
  }
  if (status === 5) {
    return "Authorized";
  }
  if (status === 6) {
    return "Completed";
  }
  if (status === 7) {
    return "Transferred";
  }
};

export function getFirstNameFromOrderId(data, orderId) {
  const matchedIncluded = data.included.find((item) => item.id === orderId);
  if (matchedIncluded) {
    return matchedIncluded.attributes["order.address.firstname"];
  }
  return null;
}
