import React from 'react';
import LeftSideBar from '../Components/LeftSideBar';
import CardFood from '../Components/CardFood';
import Menu from '../Components/Menu';
import RightBar from '../Components/RightBar';
import { Link } from 'react-router-dom';

const Asian = () => {
    return (
        <>
            <div className="all-dishes">
                <div className="items-dishes">
                    <LeftSideBar />
                    <div className="box-dishes">
                        <Menu />

                        <div className="outer-box-dishes">
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                                <div className="col-md-4">
                                    <CardFood images="../../assets/images/Biryani.webp" title="Heavy Whipping Cream, White Sugar, Vanilla Extract" sub_title="$3.99" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightBar />
                </div>
            </div>
        </>
    )
};

export default Asian;