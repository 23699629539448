import React from "react";
import Menu from "../Components/Menu";
import KitchenMenuItems from "../Components/KitchenMenuItems";

const MainLayout = ({ children, onFliterClick }) => {
  return (
    <>
      <div className="kitchen-order">
        <div className="kitchen-items">
          {/* <Menu /> */}
          <KitchenMenuItems onFliterClick={onFliterClick} />
          {children}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
