const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const API_LOGIN = `${baseUrl}/api/login`;
export const API_ME = `${baseUrl}/api/me`;
export const API_REFRESH = `${baseUrl}/api/refresh`;
export const API_REGISTER = `${baseUrl}/api/register`;
export const API_PROFILE_UPDATE = `${baseUrl}/api/profile`;
export const API_GET_ADDRESS = `${baseUrl}/jsonapi/customer?related=address`;
export const API_GET_CATALOG = `${baseUrl}/jsonapi/catalog`;
export const API_GET_PRODUCTS = `${baseUrl}/jsonapi/product`;
export const API_GET_CART = `${baseUrl}/jsonapi/basket`;
export const API_PLACE_ORDER = `${baseUrl}/jsonapi/order`;
export const API_CART_CHECKOUT = `${baseUrl}/api/checkout`;
export const API_ORDER_UPDATE = `${baseUrl}/api/order/update`;
// export const API_ORDER_LIST = `${baseUrl}/api/order/list`;
export const API_ORDER_LIST = `${baseUrl}/jsonapi/order`;
export const API_MOVE_TO_CASH = `${baseUrl}/api/order/move-to-cash`;
export const API_REFUND = `${baseUrl}/api/order/refund`;
export const API_CANCEL_TERMINAL = `${baseUrl}/api/order/terminal/cancel`;
export const API_ORDER_CONNECTION_TOKEN = `${baseUrl}/api/order/connection_token`;
export const API_ORDER_PAYMENT_INTENT = `${baseUrl}/api/order/create_payment_intent`;
export const API_ORDER_CAPTURE_PAYMENT_INTENT = `${baseUrl}/api/order/capture_payment_intent`;
