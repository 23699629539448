import React, { useEffect } from "react";

const PrintButton = (props) => {
  const { orderDetails } = props;

  function showAndroidToast(orderDetails) {
    console.log("print", orderDetails);
    // Android.showToast(orderDetails);
    dataSend.postMessage(orderDetails);
    // Android.showToast(orderDetails);
  }

  return (
    <>
      <button
        class="verification continue-btn-order"
        onClick={() => showAndroidToast(JSON.stringify(orderDetails))}
      >
        Print
      </button>
    </>
  );
};

export default PrintButton;
