import { React, useEffect, useState } from "react";
import {
  API_ORDER_CAPTURE_PAYMENT_INTENT,
  API_ORDER_CONNECTION_TOKEN,
  API_ORDER_PAYMENT_INTENT,
} from "../Config/Endpoints";

var discoveredReaders;
var paymentIntentId;

// var terminal = StripeTerminal.create({
//   onFetchConnectionToken: fetchConnectionToken,
//   onUnexpectedReaderDisconnect: unexpectedDisconnect,
// });

// function unexpectedDisconnect() {
//   // In this function, your app should notify the user that the reader disconnected.
//   // You can also include a way to attempt to reconnect to a reader.
//   console.log("Disconnected from reader");
// }

// function fetchConnectionToken() {
//   // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
//   return fetch(API_ORDER_CONNECTION_TOKEN, { method: "POST" })
//     .then(function (response) {
//       return response.json();
//     })
//     .then(function (data) {
//       return data.secret;
//     });
// }

// async function fetchConnectionToken() {
//   const response = await fetch(API_ORDER_CONNECTION_TOKEN, { method: "POST" });
//   const data = await response.json();
//   console.log("fetchConnectionToken", data.secret);
//   return data.secret;
// }

const Stripe = (props) => {
  let { terminal } = props;
  console.log("terminal", terminal);
  //   useEffect(() => {
  //     console.log("terminal", terminal);
  //   }, []);
  function discoverReaderHandler() {
    console.log("discoverReaderHandler");
    var config = { simulated: true };
    terminal.discoverReaders(config).then(function (discoverResult) {
      if (discoverResult.error) {
        console.log("Failed to discover: ", discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log("No available readers.");
      } else {
        console.log("discoverResult", discoverResult.discoveredReaders);
        discoveredReaders = discoverResult.discoveredReaders;
        console.log("terminal.discoverReaders", discoveredReaders);
      }
    });
  }

  function connectReaderHandler(discoveredReaders) {
    // Just select the first reader here.
    var selectedReader = discoveredReaders[0];
    terminal.connectReader(selectedReader).then(function (connectResult) {
      if (connectResult.error) {
        console.log("Failed to connect: ", connectResult.error);
      } else {
        console.log("Connected to reader: ", connectResult.reader.label);
        console.log("terminal.connectReader", connectResult);
      }
    });
  }

  function fetchPaymentIntentClientSecret(amount) {
    const bodyContent = JSON.stringify({ amount: amount });
    return fetch(API_ORDER_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyContent,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.client_secret;
      });
  }

  function collectPayment(amount) {
    fetchPaymentIntentClientSecret(amount).then(function (client_secret) {
      terminal.setSimulatorConfiguration({
        testCardNumber: "4242424242424242",
      });
      console.log("client_secret", client_secret);
      console.log("terminal before collectPaymentMethod", terminal);
      terminal.collectPaymentMethod(client_secret).then(function (result) {
        if (result.error) {
          // Placeholder for handling result.error
        } else {
          console.log("terminal.collectPaymentMethod", result.paymentIntent);
          terminal.processPayment(result.paymentIntent).then(function (result) {
            if (result.error) {
              console.log(result.error);
            } else if (result.paymentIntent) {
              paymentIntentId = result.paymentIntent.id;
              console.log("terminal.processPayment", result.paymentIntent);
            }
          });
        }
      });
    });
  }

  function capture(paymentIntentId) {
    console.log("paymentIntentId", paymentIntentId);
    return fetch(API_ORDER_CAPTURE_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payment_intent_id: paymentIntentId }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("server.capture", data);
      });
  }

  return (
    <>
      <h1>Stipe</h1>

      <button id="discover-button" onClick={discoverReaderHandler}>
        1. Discover readers
      </button>
      <button
        id="connect-button"
        onClick={() => connectReaderHandler(discoveredReaders)}
      >
        2. Connect to a reader
      </button>

      <button id="collect-button" onClick={(e) => collectPayment(1200)}>
        3. Collect Payment
      </button>
      <button id="capture-button" onClick={(e) => capture(paymentIntentId)}>
        4. Capture Payment
      </button>
    </>
  );
};

export default Stripe;
