import React, { useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import {
  API_GET_CART,
  API_GET_CATALOG,
  API_GET_PRODUCTS,
} from "../Config/Endpoints";
import {
  getCategoryDetailsMultiple,
  getProductDetailsMultiple,
} from "../Services/Helpers";
import MyLoader from "./MyLoader";
import { getLocalData, logoutUser, setLocalData } from "../Services/Storage";
import AddToCartModal from "./AddToCartModal";

const LeftSideBar = (props) => {
  const [show, setShow] = useState(false);
  const [categoriesList, setCategoriesList] = useState([
    { attributes: { "catalog.label": "" } },
  ]);
  const { categoryChange, addToCar, handleReloadCart } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState({ data: [] });
  const [searchResults, setSearchResults] = useState({ data: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductAddLoading, setIsProductAddLoading] = useState(false);
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const [itemSearch, setItemSearch] = useState(false);
  let activeTabId = getLocalData("activeTab");
  let search;

  const closeModal = () => {
    setIsModalOpen(false);
    setIsProductAddLoading(false);
  };

  const openModel = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      fetch(
        API_GET_CATALOG +
          "?include=catalog,media,price&id=" +
          process.env.REACT_APP_MAIN_CATEGORY_ID,
        {
          method: "GET",
          crossDomain: true,
          credentials: "include",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setCategoriesList(data.included);
          setIsLoading(false);
          if (activeTabId) {
            handleCategoryChange(`${activeTabId}`);
          } else {
            handleCategoryChange(data.included[0].id);
          }
        })
        .catch((error) => {
          //console.log(error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    const fetchAutocompleteResults = async () => {
      try {
        const response = await fetch(
          `${API_GET_PRODUCTS}?include=catalog,media,price,attribute&filter[%26%26][][%21%3D][product.mode]=WEBSITE&filter[f_search]=${searchQuery}`,
          {
            signal: controller.signal,
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        console.log("data", data);
        setAutocompleteResults(data);
      } catch (error) {
        console.error("Error fetching autocomplete results:", error);
      }
    };

    if (searchQuery.length >= 2) {
      fetchAutocompleteResults();
    } else {
      setAutocompleteResults({ data: [] });
    }

    // Cancel previous incomplete request when the query changes
    return () => {
      controller.abort();
      controller = new AbortController();
    };
  }, [searchQuery]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    categoryChange(categoryId);
    setLocalData("activeTab", categoryId);
  };

  const handleOnSearchClick = (e, item) => {
    setItemSearch(item);
    // search = item;
    // console.log(search);
    setSearchResults(autocompleteResults);
    openModel();
    // addToCart(e, item);
    setSearchQuery("");
    setAutocompleteResults({ data: [] });
  };

  const handleHardRefresh = () => {
    console.log("Refreshing");
    hardRefresh.postMessage("refresh");
  };

  return (
    <>
      <div className="outer-dishes">
        <div className="dishes-items-1" onClick={setIsLogoModalOpen}>
          <img src="../../assets/images/knife_bg.svg" alt="" />
        </div>
        <div className="dishes-user-items">
          {!isLoading && selectedCategory ? (
            categoriesList.map((item, key) =>
              item.type == "catalog" ? (
                <div className={`dishes-items`} key={key}>
                  <NavLink
                    key={key}
                    to="/"
                    onClick={(e) => handleCategoryChange(item.id, e)}
                    className={`${
                      item.id === selectedCategory ? "active-1" : ""
                    }`}
                  >
                    <img
                      src={
                        getCategoryDetailsMultiple(categoriesList, item).media
                          ? getCategoryDetailsMultiple(categoriesList, item)
                              .media
                          : "../../assets/images/bestseller.png"
                      }
                      alt=""
                    />
                    <p>{item.attributes["catalog.label"]}</p>
                  </NavLink>
                </div>
              ) : null
            )
          ) : (
            <MyLoader />
          )}
        </div>
        {show ? (
          <div class="search-box">
            <div className="search-input">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={searchQuery}
                onChange={handleInputChange}
                autoFocus
              />
              <a
                href="#!"
                onClick={() => {
                  setSearchQuery("");
                  setAutocompleteResults({ data: [] });
                  setShow(false);
                }}
              >
                ✕
              </a>
              {/* <i className="fa fa-search"></i> */}
            </div>
            {searchQuery !== "" && autocompleteResults.data.length === 0 && (
              <h4 className="no-record-found">NO RESULTS FOUND</h4>
            )}
            <ul className="autocomplete-results">
              {autocompleteResults.data.map((item, key) => (
                <li onClick={(e) => handleOnSearchClick(e, item)} key={key}>
                  {item.attributes["product.label"]}
                  <span>
                    $
                    {getProductDetailsMultiple(autocompleteResults, item).price
                      ? getProductDetailsMultiple(autocompleteResults, item)
                          .price.attributes["price.value"]
                      : "-"}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="search-and-log-out">
          <span className="login-first-page">
            <img
              src="../../assets/images/search.svg"
              alt=""
              onClick={() => setShow(!show)}
            />
          </span>

          {/* <i className="fa fa-search" onClick={() => setShow(!show)}></i> */}
        </div>
      </div>

      {isModalOpen && (
        <AddToCartModal
          title={itemSearch.attributes["product.label"]}
          price={`${
            getProductDetailsMultiple(searchResults, itemSearch).price
              ? getProductDetailsMultiple(searchResults, itemSearch).price
                  .attributes["price.value"]
              : "-"
          }
          `}
          item={itemSearch}
          productList={searchResults}
          onClose={closeModal}
          handleReloadCart={handleReloadCart}
        />
      )}

      {isLogoModalOpen && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div class="action-buttons modal-content">
                <button type="button" onClick={(e) => handleHardRefresh(e)}>
                  <img src="../../assets/images/refresh.svg" alt="" />
                  <span>Hard Refresh</span>
                </button>
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    const confirmLogout = window.confirm(
                      "Are you sure you want to logout?"
                    );
                    if (confirmLogout) {
                      logoutUser();
                    }
                  }}
                >
                  <img src="../../assets/images/power-off.svg" alt="" />
                  <span>Log Out</span>
                </button>
                <button
                  type="button"
                  class=""
                  onClick={(e) => setIsLogoModalOpen(false)}
                >
                  <img src="../../assets/images/close.svg" alt="" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LeftSideBar;
