import React from 'react';
import { Link } from 'react-router-dom';
import BoxFood from '../Components/BoxFood';
import LeftSideBar from '../Components/LeftSideBar';
import Menu from '../Components/Menu';
import RightBar from '../Components/RightBar';


const GridItemsFive = () => {
    return (
        <>
            <div className="all-dishes">
                <div className="items-dishes">
                    <LeftSideBar />
                    <div className="box-dishes">
                        <div className="menu-header">
                            <Menu />
                            <div className="grid-img">

                                <Link to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h14.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7 H0.8C0.4,7,0,6.6,0,6.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z"></path></svg>
                                </Link>
                                <Link to="/GridItemsThree">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M15.2,16H9.8C9.4,16,9,15.6,9,15.2V9.8C9,9.4,9.4,9,9.8,9h5.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7 H9.8C9.4,7,9,6.6,9,6.2V0.8C9,0.4,9.4,0,9.8,0h5.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z M6.2,16H0.8 C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h5.4C6.6,9,7,9.4,7,9.8v5.4C7,15.6,6.6,16,6.2,16z M6.2,7H0.8C0.4,7,0,6.6,0,6.2V0.8 C0,0.4,0.4,0,0.8,0h5.4C6.6,0,7,0.4,7,0.8v5.4C7,6.6,6.6,7,6.2,7z"></path></svg>
                                </Link>
                                <Link to="/GridItemsFive">
                                    <img src="../../assets/images/five.png" alt="five" className="five" />
                                </Link>
                            </div>
                        </div>
                        <div className="outer-box-dishes">
                            <div className="row g-4 mb-2">
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                            </div>
                            <div className="row g-4 mb-2">
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                            </div>
                            <div className="row g-4">
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                                <div className="col">
                                    <BoxFood images="../../assets/images/category-4.jpg" title="chicken, egg, mushroom, salad" sub_title="$10.99" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightBar />
                </div>
            </div>
        </>
    )
};

export default GridItemsFive;